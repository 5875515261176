import styles from "./editAvailabilityPage.module.css";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Calendar from "react-calendar";
import { useAppSelector } from "../../services/hooks";
import { availabilitySelector } from "../../services/selectors/user.selectors";
import Modal from "../../components/modals/modal/Modal";
import EditAvailabilityPopup from "../../components/modals/editAvailabilityPopup/editAvailabilityPopup";

function EditAvailabilityMonthlyPage() {
  const navigate = useNavigate();
  const [activeDate, setActiveDate] = useState<null | Date>(null);

  const availability = useAppSelector(availabilitySelector);

  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"לחזור"}
        onClick={() => navigate("/calendar")}
        position={"center"}
      />
      <h2 className={styles.title}>זמינות שלי</h2>
      <p className={`${styles.text} ${[styles.text_position_center]}`}>
        כדי להגדיר שעות זמינות לחץ על יום מסוים
      </p>
      <div className={styles.calendarSection}>
        <Calendar
          calendarType={"hebrew"}
          locale={"he"}
          onClickDay={setActiveDate}
          value={activeDate}
          prev2Label={null}
          next2Label={null}
          minDate={new Date()}
          tileDisabled={({ date }) => date.getDay() === 6}
          tileContent={({ date }) => {
            if (availability[date.getDay()]?.ranges.length > 0) {
              return (
                <div className={styles.circleContainer}>
                  <span className={styles.circle} />
                </div>
              );
            } else {
              return null;
            }
          }}
        />
      </div>
      {activeDate !== null && availability[activeDate.getDay()] && (
        <Modal
          onClose={() => {
            setActiveDate(null);
          }}
        >
          <EditAvailabilityPopup
            day={availability[activeDate.getDay()]}
            onClose={() => {
              setActiveDate(null);
            }}
          />
        </Modal>
      )}
    </section>
  );
}

export default EditAvailabilityMonthlyPage;
