import { useEffect, useRef } from "react";
import { IChatAppointment } from "../services/types/user.types";
import { SOCKET_SERVER_URL } from "../utils/constants";
import { useAppSelector } from "../services/hooks";
import {
  chatConnectionsSelector,
  chatUsersSelector,
  userSelector,
} from "../services/selectors/user.selectors";
import { useActions } from "../services/hooks/useActions";
import { IMessage } from "../types/types";
import { IWSMessage } from "../services/types/auth.types";
import { useLazySendUserEnteredSMSToTherapistQuery } from "../services/api/user.api";

export interface IWebSocketConnection {
  userId: string | number;
  appointment: IChatAppointment;
  ws: WebSocket;
  sendMessage: (message: any) => void;
  closeConnection: () => void;
  readyState: number;
  messages: IMessage[];
  isTyping: boolean;
}

const useMultipleWebSockets = () => {
  const chatUsers = useAppSelector(chatUsersSelector);
  // console.log(chatUsers);
  const connections = useAppSelector(chatConnectionsSelector);
  const { setConnections, updateWSState } = useActions();
  const therapist = useAppSelector(userSelector);
  const [sendUserJoinedSMSToTherapist] =
    useLazySendUserEnteredSMSToTherapistQuery();
  const connectionsRef = useRef<IWebSocketConnection[]>([]);

  useEffect(() => {
    const newConnections: IWebSocketConnection[] = [];

    chatUsers.forEach((u) => {
      const appointment = u.appointment;
      if (appointment && appointment.Active) {
        let existingConnection = connections.find(
          (conn) => conn.appointment.Id === appointment.Id
        );

        if (!existingConnection) {
          const ws = new WebSocket(
            `${SOCKET_SERVER_URL}?username=${therapist.id}${appointment.Id}&roomId=${appointment.Id}`
          );
          const sendMessage = (message: IMessage | IWSMessage) => {
            if (ws.readyState === WebSocket.OPEN) {
              ws.send(JSON.stringify(message));
            }
          };
          const closeConnection = () => {
            if (ws.readyState === WebSocket.OPEN) {
              sendMessage({
                Type: "CHAT",
                Sender: `${therapist.id}${appointment.Id}`,
                Users: [],
                Content: "CLOSE",
                RoomId: appointment.Id.toString() || "",
              });
              ws.close();
            }
          };

          ws.onopen = () => {};
          ws.onmessage = (event) => {
            const messageData: IWSMessage = JSON.parse(event.data);
            const { Type, Sender, Content } = messageData;
            if (
              Type === "CONNECTION" &&
              String(Sender) === String(u.userId) &&
              Content.includes("joined")
            ) {
              sendUserJoinedSMSToTherapist({
                appointmentId: appointment.Id,
                userId: u.userId,
              });
            }
            updateWSState(messageData);
          };
          ws.onclose = (event) => {
            if (event.reason.includes("exists")) {
              updateWSState({
                Type: "ERROR",
                Sender: therapist.id,
                Content: "אתה כבר מחובר במכשיר אחר",
                RoomId: u.userId.toString(),
                Users: [],
              });
            }
            console.log(
              `WebSocket connection closed for user ${u.userId}.`,
              event
            );
          };
          ws.onerror = (error) =>
            console.error(`WebSocket error for user ${u.userId}:`, error);

          existingConnection = {
            userId: u.userId,
            appointment: appointment,
            ws,
            sendMessage,
            readyState: ws.readyState,
            messages: [],
            closeConnection,
            isTyping: false,
          };

          newConnections.push(existingConnection);
        } else {
          newConnections.push(existingConnection);
        }
      }
    });

    // Update state and ref with new connections
    setConnections(newConnections);
    connectionsRef.current = newConnections;

    // Close connections for users no longer in the list
    connections.forEach((conn) => {
      if (!chatUsers.find((us) => us.appointment?.Id === conn.appointment.Id)) {
        conn.ws.close();
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    chatUsers,
    sendUserJoinedSMSToTherapist,
    setConnections,
    therapist,
    updateWSState,
    document.visibilityState,
  ]);

  return connections;
};

export default useMultipleWebSockets;
