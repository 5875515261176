import styles from "./addButton.module.css";

interface IAddButtonProps {
  onClick: () => void;
  additionalClassName?: string;
  title: string;
}
function AddButton({ onClick, additionalClassName, title }: IAddButtonProps) {
  return (
    <button
      className={`${styles.btn} ${additionalClassName}`}
      onClick={onClick}
    >
      <span className={styles.btn__plus}>+</span>
      {title}
    </button>
  );
}

export default AddButton;
