import styles from "./filterButton.module.css";
import { filterOptions } from "../../utils/constants";
import { statuses } from "../../utils/therapistDocuments";
import React, { Dispatch, SetStateAction, useState } from "react";
import DatePicker, { Value } from "react-multi-date-picker";
import filterIcon from "../../images/filter_icon.svg";

interface IFilterButtonProps {
  filter: string | undefined;
  setFilter: Dispatch<SetStateAction<string | undefined>>;
  statusFilter: string | undefined;
  setStatusFilter: Dispatch<SetStateAction<string | undefined>>;
  dateValue: Value;
  setDateValue: Dispatch<SetStateAction<Value>>;
  filterDocumentsByDate: () => void;
}

function FilterButton({
  filter,
  setFilter,
  statusFilter,
  setStatusFilter,
  dateValue,
  setDateValue,
  filterDocumentsByDate,
}: IFilterButtonProps) {
  const [filtersVisible, setFiltersVisible] = useState(false);
  return (
    <>
      <button
        className={`${styles.btn} ${styles.btn_type_filter} hover`}
        onClick={(e) => {
          e.stopPropagation();
          setFiltersVisible(!filtersVisible);
        }}
      >
        <img className={styles.filterIcon} src={filterIcon} alt={"סינון"} />
        סינון
      </button>
      {filtersVisible && (
        <ul className={styles.filterOptions}>
          {filterOptions.map((f, index) => (
            <li
              key={index}
              className={`${styles.filterOption} ${
                f.value === "status" && styles.filterOption_type_status
              } ${filter === "status" && styles.filterOption_type_status_open}`}
              onClick={(e) => {
                e.stopPropagation();
                setFilter(f.value);
                if (f.value !== "status" && f.value !== "date") {
                  setFiltersVisible(false);
                }
              }}
            >
              <input
                type="radio"
                value={f.value}
                id={f.value}
                name="sort"
                className={styles.radio}
                checked={filter === f.value}
                onChange={() => {}}
              />
              <label htmlFor={f.value} className={styles.label}>
                {f.label}
              </label>
              {f.value === "status" && (
                <ul className={styles.statusOptions}>
                  {statuses.map((s, index) => (
                    <li
                      key={index}
                      className={`${styles.filterOption} ${styles.statusOption}`}
                      onClick={() => {
                        setStatusFilter(s.class);
                        setFiltersVisible(false);
                      }}
                    >
                      <input
                        type="radio"
                        value={s.class}
                        id={s.class}
                        name="statusSort"
                        className={`${styles.radio} ${styles.radio_type_status}`}
                        checked={statusFilter === s.class}
                        onChange={() => {}}
                      />
                      <label htmlFor={s.class} className={styles.label}>
                        {s.text}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
          {filter === "date" && (
            <div className={styles.dateFilterContainer}>
              <DatePicker value={dateValue} onChange={setDateValue} />
              <button
                onClick={() => {
                  filterDocumentsByDate();
                  setFiltersVisible(false);
                }}
                className={`${styles.filterBtn}`}
              >
                סנן
              </button>
            </div>
          )}
          <li
            className={`${styles.filterOption}`}
            onClick={() => {
              setFilter("clearFilters");
              setFiltersVisible(false);
            }}
          >
            <input
              type="radio"
              value={"clearFilters"}
              id={"clearFilters"}
              name="sort"
              className={styles.radio}
              checked={filter === "clearFilters"}
              onChange={() => {}}
            />
            <label htmlFor={"clearFilters"} className={styles.label}>
              ללא מסננים
            </label>
          </li>
        </ul>
      )}
    </>
  );
}

export default FilterButton;
