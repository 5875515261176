import React, { Dispatch, SetStateAction } from "react";
import styles from "./listPagination.module.css";
import { TLimits } from "../../types/types";

interface IListPaginationProps {
  limits: TLimits;
  total: number;
  step: number;
  setLimits: Dispatch<SetStateAction<TLimits>>;
}
function ListPagination({
  limits,
  total,
  setLimits,
  step,
}: IListPaginationProps) {
  const onNextClick = () => {
    if (limits.end < total) {
      setLimits((prev) => ({
        ...prev,
        start: prev.start + step,
        end: prev.end + step < total ? prev.end + step : total,
      }));
    }
  };
  const onPrevClick = () => {
    if (limits.start > 1) {
      setLimits((prev) => ({
        ...prev,
        start: prev.start - step,
        end: prev.start - 1,
      }));
    }
  };
  return (
    <article className={styles.pagination}>
      <button
        className={`${styles.btn} ${styles.btn_forward}`}
        onClick={onPrevClick}
      />
      מוצג {limits.start} - {limits.end} מתוך {total}
      <button
        className={`${styles.btn} ${styles.btn_backward}`}
        onClick={onNextClick}
      />
    </article>
  );
}

export default ListPagination;
