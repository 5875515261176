import popupStyles from "./signup_success_popup.module.css";
import { mobileWidth } from "../../../utils/constants";
import { IPopupWithoutProps } from "../../../types/types";
import prevenchik from "../../../images/venchy_welcome.png";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { useAppSelector } from "../../../services/hooks";
import { userSelector } from "../../../services/selectors/user.selectors";

function SignupSuccessPopup({ onClose }: IPopupWithoutProps) {
  const mobile = useMediaQuery(mobileWidth);
  const user = useAppSelector(userSelector);

  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}>ברוך הבא!</h2>
      {mobile && (
        <img
          className={popupStyles.popup__img}
          src={prevenchik}
          alt={"וונצ’י"}
        />
      )}
      <p className={popupStyles.popup__text}>המערכת יצרה עבורך מספר משתמש</p>
      <p className={popupStyles.popup__account}>{user.account}</p>
      <p className={popupStyles.popup__text}>
        שמור מספר זה, הוא ישרת אותך להזדהות בפריוונצר או כמשתמש אנונימי
      </p>
      <button
        className={`${popupStyles.popup__nextBtn} hover`}
        onClick={onClose}
      >
        אני רוצה להתחיל!
      </button>
    </article>
  );
}

export default SignupSuccessPopup;
