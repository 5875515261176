import styles from "./editDataPage.module.css";
import { userActionsEdit, userActionsEditShort } from "../../utils/user";
import { Link, useNavigate, useParams } from "react-router-dom";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import useMediaQuery from "../../hooks/useMediaQuery";
import { mobileWidth, testUserEmail } from "../../utils/constants";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import { useEffect, useState } from "react";

function EditDataPage() {
  const { id: userId } = useParams();
  const navigate = useNavigate();
  const mobile = useMediaQuery(mobileWidth);
  const therapist = useAppSelector(userSelector);
  const isTestUser = therapist?.email.toLowerCase() === testUserEmail;
  const [options, setOptions] = useState(userActionsEditShort);

  useEffect(() => {
    if (isTestUser) {
      setOptions(userActionsEdit);
    } else {
      setOptions(userActionsEditShort);
    }
  }, [isTestUser, setOptions]);

  return (
    <article className={styles.section}>
      {!mobile && (
        <BackArrowButton
          text={"למסך הקודם"}
          onClick={() => navigate(-1)}
          position={"center"}
        />
      )}
      <ul className={styles.editOptions}>
        {options.map((a, index) => (
          <Link
            to={`/users/${userId}/${a.path} `}
            key={index}
            className={`${styles.editOption} hover`}
          >
            {a.text}
          </Link>
        ))}
        {mobile && (
          <button
            onClick={() => navigate(-1)}
            className={`button ${styles.btn} hover`}
          >
            למסך הקודם
          </button>
        )}
      </ul>
    </article>
  );
}

export default EditDataPage;
