import React from "react";
import popupStyles from "./prevencherAnswer_popup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IContactUsTheme } from "../../../utils/categories";
import { useNavigate } from "react-router-dom";
import useMediaQuery from "../../../hooks/useMediaQuery";
import { mobileWidth } from "../../../utils/constants";

interface IPrevencherAnswerPopupProps {
  onClose: (e: React.MouseEvent) => void;
  theme: IContactUsTheme;
}
function PrevencherAnswerPopup({
  onClose,
  theme,
}: IPrevencherAnswerPopupProps) {
  const navigate = useNavigate();
  const mobile = useMediaQuery(mobileWidth);

  return (
    <article className={popupStyles.popup}>
      {!mobile && <h2 className={popupStyles.popup__title}>{theme.title}</h2>}
      <div className={popupStyles.prevencher}>
        <img
          src={doctor}
          alt="תמונה של וונצ’י"
          className={popupStyles.popup__img}
        />
        <p className={popupStyles.prevencher__name}>פריוונצ’ר</p>
      </div>
      {mobile && <h2 className={popupStyles.popup__title}>{theme.title}</h2>}
      <p className={popupStyles.popup__text}>{theme.text}</p>
      <div className={popupStyles.buttonBar}>
        <button
          className={`${popupStyles.popup__nextBtn} ${popupStyles.popup__nextBtn_white} hover`}
          onClick={() => {
            navigate("/profile", {
              replace: true,
              state: { pathname: "techSupport" },
            });
          }}
        >
          אני רוצה לפתוח פנייה
        </button>
        <button
          className={`${popupStyles.popup__nextBtn} hover`}
          onClick={onClose}
        >
          הבנתי, תודה
        </button>
      </div>
    </article>
  );
}

export default PrevencherAnswerPopup;
