import styles from "./userInfo.module.css";
import React from "react";
import {
  TTherapistUser,
  TTherapistWithUsers,
} from "../../services/types/user.types";
import { calculateAge, noDataLabel } from "../../utils/constants";

function UserInfo({
  user,
  customClassName,
}: {
  user: TTherapistWithUsers | TTherapistUser;
  customClassName?: string;
}) {
  const usersId = "UsersId" in user ? user.UsersId : user.Id;
  return (
    <section className={`${styles.userSection} ${customClassName}`}>
      <article className={styles.user}>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            שם משתמש:
          </p>
          <p className={styles.user__text}>
            {user?.FirstName || ""} {user?.LastName || ""}
          </p>
        </div>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            מס' משתמש:
          </p>
          <p className={styles.user__text}>{usersId}</p>
        </div>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            גיל:
          </p>
          <p className={styles.user__text}>
            {user.Birth ? calculateAge(user.Birth) : "אין מידע"}
          </p>
        </div>
        <div>
          <p className={`${styles.user__label} ${styles.form__subtitle}`}>
            נייד:
          </p>
          <p className={styles.user__text}>{user?.Phone || noDataLabel}</p>
        </div>
      </article>
    </section>
  );
}

export default UserInfo;
