import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface SocketState {
  isConnected: boolean;
  messages: string[];
}

const initialState: SocketState = {
  isConnected: false,
  messages: [],
};

const socketSlice = createSlice({
  name: "socket",
  initialState,
  reducers: {
    socketConnected: (state) => {
      state.isConnected = true;
    },
    socketDisconnected: (state) => {
      state.isConnected = false;
    },
    addMessage: (state, action: PayloadAction<string>) => {
      state.messages.push(action.payload);
    },
  },
});

export const { socketConnected, socketDisconnected, addMessage } =
  socketSlice.actions;

export default socketSlice.reducer;
