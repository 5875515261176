import forumThreadStyles from "./forumThread.module.css";
import { forumThreadExample } from "../../utils/forum";
import ForumMessage from "../forumMessage/forumMessage";
import BackArrowButton from "../backArrowButton/backArrowButton";

function ForumThread({ onClose }: { onClose: () => void }) {
  return (
    <section className={forumThreadStyles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={onClose}
        position={"center"}
      />
      <h2 className={forumThreadStyles.title}>{forumThreadExample.title}</h2>
      <ul className={forumThreadStyles.list}>
        {forumThreadExample.messages.map((m, index) => (
          <ForumMessage key={index} message={m} />
        ))}
      </ul>
    </section>
  );
}

export default ForumThread;
