import { useFormik } from "formik";
import signinFormStyles from "./signinForms.module.css";
import { ISigninFormProps } from "../../../types/signinTypes";
import { signUpValidationSchema2 } from "../../../utils/validation";
import { phonePrefixOptions } from "../../../utils/constants";
import React, { useState } from "react";

function SigninForm2({ state, setState, changeForm }: ISigninFormProps) {
  const [phonePrefix, setPhonePrefix] = useState(phonePrefixOptions[0]);
  const onChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setPhonePrefix(value);
  };

  const formik = useFormik({
    initialValues: {
      birth: state.birth,
      phone: state.phone.split("-")[1],
      email: state.email,
      degree: state.degree,
    },
    validationSchema: signUpValidationSchema2,
    onSubmit: (values) => {
      // Handle form submission
      setState({
        ...state,
        birth: values.birth,
        email: values.email,
        phone: `${phonePrefix}${values.phone}`,
        degree: values.degree,
      });
      changeForm();
    },
  });

  return (
    <form
      className={signinFormStyles.signin_private__form}
      onSubmit={formik.handleSubmit}
    >
      <label htmlFor="birth" className={signinFormStyles.signin_private__label}>
        תאריך לידה
      </label>
      <input
        type="date"
        className={`${signinFormStyles.signin_private__input} ${
          formik.errors.birth && signinFormStyles.signin_private__input_invalid
        }`}
        name="birth"
        id="birth"
        value={formik.values.birth}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="שם פרטי"
        required={true}
        autoFocus={true}
      />
      <label htmlFor="phone" className={signinFormStyles.signin_private__label}>
        טלפון נייד
      </label>
      <div className={signinFormStyles.signin_private__phone_inputs}>
        <input
          type="text"
          className={`${signinFormStyles.signin_private__input} ${
            formik.errors.phone &&
            signinFormStyles.signin_private__input_invalid
          }`}
          name="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          placeholder="טלפון נייד"
          required={true}
        />
        <select
          value={phonePrefix}
          onChange={onChange}
          className={`${signinFormStyles.signin_private__input} ${signinFormStyles.signin_private__select}`}
        >
          {phonePrefixOptions.map((prefix, index) => (
            <option key={index} value={prefix}>
              {prefix}
            </option>
          ))}
        </select>
      </div>

      <label htmlFor="email" className={signinFormStyles.signin_private__label}>
        דואר אלקטרוני
      </label>
      <input
        type="email"
        className={`${signinFormStyles.signin_private__input} ${
          formik.errors.email && signinFormStyles.signin_private__input_invalid
        }`}
        name="email"
        id="email"
        value={formik.values.email}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        placeholder="דואר אלקטרוני"
        required={true}
      />
      <label
        htmlFor="degree"
        className={signinFormStyles.signin_private__label}
      >
        תואר
      </label>
      <input
        type="degree"
        className={`${signinFormStyles.signin_private__input} ${
          formik.errors.degree && signinFormStyles.signin_private__input_invalid
        }`}
        name="degree"
        id="degree"
        value={formik.values.degree}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        required={true}
      />
      {formik.errors && (
        <p className={`${signinFormStyles.signin_private__invalid_message}`}>
          {formik.errors.birth ?? formik.errors.phone ?? formik.errors.email}
        </p>
      )}
      <button
        type="submit"
        disabled={!formik.isValid || formik.isSubmitting}
        className={`${signinFormStyles.signin_private__btn} ${
          !formik.isValid || formik.isSubmitting
            ? signinFormStyles.signin_private__btn_disabled
            : "hover"
        }`}
      >
        המשך
      </button>
    </form>
  );
}

export default SigninForm2;
