import popupStyles from "./restore_password_success_popup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";

function RestorePasswordSuccessPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}>התהליך הסתיים בהצלחה!</h2>
      <p className={popupStyles.popup__text}>
        אנא בדוק את האימייל שלך כדי למצוא מידע נוסף.{" "}
      </p>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={popupStyles.popup__img}
      />
      <button
        className={`button hover ${popupStyles.popup__nextBtn}`}
        onClick={onClose}
      >
        אישור
      </button>
    </article>
  );
}

export default RestorePasswordSuccessPopup;
