import styles from "./slider.module.css";
import {
  Dispatch,
  SetStateAction,
  TouchEvent,
  useCallback,
  useState,
} from "react";
import { IVideo } from "../../types/types";
import Video from "../video/video";

function Slider({
  items,
  className,
  activeIndex,
  setActiveIndex,
}: {
  items: string[] | IVideo[];
  className: string;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
}) {
  const [touchStartX, setTouchStartX] = useState<number | null>(null);

  const handlePrev = useCallback(() => {
    setActiveIndex((prevIndex: number) =>
      prevIndex === 0 ? items.length - 1 : prevIndex - 1
    );
  }, [items.length]);
  const handleNext = useCallback(() => {
    setActiveIndex((prevIndex) =>
      prevIndex === items.length - 1 ? 0 : prevIndex + 1
    );
  }, [items.length]);
  const handleTouchStart = (event: TouchEvent) => {
    if (touchStartX) return;
    setTouchStartX(event.touches[0].clientX);
  };
  const handleTouchEnd = (event: TouchEvent) => {
    if (touchStartX === null) return;
    const currentX = event.changedTouches[0]?.clientX;
    const diffX = touchStartX - currentX;

    if (diffX > 50) {
      handlePrev();
    } else if (diffX < -50) {
      handleNext();
    }
    setTouchStartX(null);
  };

  return (
    <div className={styles.carouselWrapper}>
      <div className={styles.carouselItems}>
        {items.map((item, index: number) => {
          if (typeof item === "string") {
            return (
              <div
                key={index}
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => handleTouchEnd(e)}
                className={`${styles.carouselItem} `}
                style={{
                  transform: `translateX(${activeIndex * 100}%)`,
                }}
              >
                <p className={className}>{item}</p>
              </div>
            );
          } else {
            return (
              <li
                onTouchStart={handleTouchStart}
                onTouchEnd={(e) => handleTouchEnd(e)}
                style={{
                  transform: `translateX(${activeIndex * 100}%)`,
                }}
                key={index}
                className={`${styles.slider__sectionCard} ${styles.slider__sectionCard_centered}`}
              >
                <div className={styles.slider__sectionContent}>
                  <p
                    className={`${styles.slider__sectionText} ${styles.slider__sectionText_centered} ${styles.slider__sectionText_small}`}
                  >
                    {item.title}
                  </p>
                  <Video src={item.link} />
                </div>
              </li>
            );
          }
        })}
      </div>
    </div>
  );
}

export default Slider;
