import styles from "./mainPage.module.css";
import React, { useEffect, useState } from "react";
import {
  RRMListDefault,
  testUserEmail,
  therapistLinks,
  mainPageTexts,
  mobileWidth,
} from "../../utils/constants";
import venchy from "../../images/questionnaire_doctor.png";
import { getPrintDate, replaceVariables } from "../../utils/utils";
import Slider from "../../components/slider/slider";
import Pagination from "../../components/pagination/pagination";
import useMediaQuery from "../../hooks/useMediaQuery";
import Document from "../../components/document/document";
import {
  useGetStatusesQuery,
  useLazyGetMainPageInfoQuery,
} from "../../services/api/user.api";
import { useAppSelector } from "../../services/hooks";
import {
  mainPageUpdatesSelector,
  statusesSelector,
  therapistWithUsersSelector,
  userSelector,
  usersHosenSderotSelector,
  usersWithOutTherapistSelector,
} from "../../services/selectors/user.selectors";
import { IDatedText, TLimits } from "../../types/types";
import {
  TTherapistWithUsers,
  TUsersWithOutTherapist,
} from "../../services/types/user.types";
import { getServerStatusText } from "../../utils/therapistDocuments";
import { useNavigate } from "react-router-dom";
import ListPagination from "../../components/listPagination/listPagination";
import FilterButtonMainPage from "../../components/filterButton/filterButtonMainPage";
import Loader from "../../components/loader/loader";

function MainPage() {
  const navigate = useNavigate();
  const therapist = useAppSelector(userSelector);
  const isTestUser = therapist?.email.toLowerCase() === testUserEmail;
  const serverStatuses = useAppSelector(statusesSelector);

  const [getMainPageInfo, { isLoading: isMainPageInfoLoading, isError }] =
    useLazyGetMainPageInfoQuery();
  useGetStatusesQuery();

  const isLoading = isMainPageInfoLoading || !therapist.id;
  const updates = useAppSelector(mainPageUpdatesSelector) as IDatedText[];
  const mobile = useMediaQuery(mobileWidth);
  const therapistWithUsers = useAppSelector(therapistWithUsersSelector);
  const usersWithOutTherapist = useAppSelector(usersWithOutTherapistSelector);
  const usersHosenSderot = useAppSelector(usersHosenSderotSelector);

  const [activeIndex, setActiveIndex] = useState(0);
  const [documentToShow, setDocumentToShow] = useState<
    TTherapistWithUsers | TUsersWithOutTherapist | null
  >(null);
  const [openDocument, setOpenDocument] = useState(false);

  const [therapistWithUsersToShow, setTherapistWithUsersToShow] = useState<
    TTherapistWithUsers[] | undefined
  >(undefined);
  const [usersWithOutTherapistToShow, setUsersWithOutTherapistToShow] =
    useState<TUsersWithOutTherapist[] | undefined>(undefined);
  const [usersHosenSderonToShow, setUsersHosenSderonToShow] = useState<
    TUsersWithOutTherapist[] | undefined
  >(undefined);

  //limit inquiries to 7
  const limitNumber = 7;
  const [therapistWithUsersLimits, setTherapistWithUsersLimits] =
    useState<TLimits>({ start: 1, end: 1 });
  const [usersWithoutTherapistLimits, setUsersWithoutTherapistLimits] =
    useState<TLimits>({ start: 1, end: 1 });
  const [usersHosenSderonLimits, setUsersHosenSderonLimits] = useState<TLimits>(
    { start: 1, end: 1 }
  );

  useEffect(() => {
    if (therapist.id) {
      getMainPageInfo(therapist.id);
    }
  }, [therapist.id, getMainPageInfo]);
  useEffect(() => {
    if (therapistWithUsersToShow && therapistWithUsersToShow.length > 0) {
      setTherapistWithUsersLimits((prev) => ({
        ...prev,
        end:
          limitNumber < therapistWithUsersToShow.length
            ? limitNumber
            : therapistWithUsersToShow.length,
      }));
    }
    if (usersWithOutTherapistToShow && usersWithOutTherapistToShow.length > 0) {
      setUsersWithoutTherapistLimits((prev) => ({
        ...prev,
        end:
          limitNumber < usersWithOutTherapistToShow.length
            ? limitNumber
            : usersWithOutTherapistToShow.length,
      }));
    }
    if (usersHosenSderonToShow && usersHosenSderonToShow.length > 0) {
      setUsersHosenSderonLimits((prev) => ({
        ...prev,
        end:
          limitNumber < usersHosenSderonToShow.length
            ? limitNumber
            : usersHosenSderonToShow.length,
      }));
    }
  }, [
    therapistWithUsersToShow,
    usersWithOutTherapistToShow,
    usersHosenSderonToShow,
  ]);
  useEffect(() => {
    if (therapistWithUsers) setTherapistWithUsersToShow(therapistWithUsers);
    if (usersWithOutTherapist)
      setUsersWithOutTherapistToShow(usersWithOutTherapist);
    if (usersHosenSderot) setUsersHosenSderonToShow(usersHosenSderot);
  }, [therapistWithUsers, usersWithOutTherapist, usersHosenSderot]);

  //update document to show possible changes

  useEffect(() => {
    const findDocument = (num: string | number) =>
      therapistWithUsers?.find((item) => item.Num === num) ||
      usersWithOutTherapist?.find((item) => item.Num === num) ||
      usersHosenSderot?.find((item) => item.Num === num);
    if (documentToShow) {
      const updated = findDocument(documentToShow.Num);
      setDocumentToShow(updated || null);
    }
  }, [
    therapistWithUsers,
    documentToShow,
    usersWithOutTherapist,
    usersHosenSderot,
  ]);

  const showDocument = (doc: TTherapistWithUsers | TUsersWithOutTherapist) => {
    setDocumentToShow(doc);
    setOpenDocument(true);
  };

  return (
    <section className={styles.section}>
      <ul className={styles.tabs}>
        {therapistLinks.map((tab) => (
          <li //change to Link when needed
            key={tab.title}
            // to={`/${tab.value}`}
            className={`${styles.tab} ${
              tab.value !== "messages" && styles.tab_disabled
            } ${isTestUser && "hover"}`}
            onClick={() => {
              if (tab.value === "messages") {
                navigate(`/${tab.value}`);
              }
            }}
          >
            <img src={tab.img} alt={tab.title} className={styles.tabsImg} />
            <p className={styles.tabText}>{tab.title}</p>
          </li>
        ))}
      </ul>
      <div className={styles.content}>
        {documentToShow && (
          <Document
            document={documentToShow}
            openDocument={openDocument}
            onClose={() => setOpenDocument(false)}
          />
        )}
        <article className={styles.applications}>
          <div className={styles.home__section}>
            <h4 className={styles.home__sectionTitle}>עדכונים</h4>
            <div className={styles.notifications}>
              <div className={styles.notifications__venchy}>
                <img
                  src={venchy}
                  className={styles.notifications__img}
                  alt={"תמונה של וונצ’י"}
                />
                <div>
                  <p className={styles.notifications__name}>וונצ’י</p>
                  <p className={styles.notifications__date}>
                    {getPrintDate(updates[activeIndex].date)}
                  </p>
                </div>
              </div>
              <Slider
                items={updates.map((u) => replaceVariables(u.text, therapist))}
                className={styles.notifications__text}
                activeIndex={activeIndex}
                setActiveIndex={setActiveIndex}
              />
              <button
                className={styles.nextBtn}
                onClick={() =>
                  setActiveIndex((prevIndex) =>
                    prevIndex === updates.length - 1 ? 0 : prevIndex + 1
                  )
                }
              />
            </div>
          </div>
          <Pagination
            items={mainPageTexts.map((t) => t.text)}
            onClick={(index: number) => setActiveIndex(index)}
            activeIndex={activeIndex}
          />
        </article>
        <article className={styles.home__section}>
          <h4 className={styles.home__sectionTitle}>
            פניות חדשות של מרכז חוסן שדרות
          </h4>
          {usersHosenSderot && usersHosenSderot.length > 0 ? (
            <ul className={styles.inquiries}>
              <li className={`${styles.inquiry} ${styles.inquiryHeader}`}>
                <p className={`${styles.inquiryCell} ${styles.inquiryNumber}`}>
                  מס' פניה
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryDate}`}>
                  תאריך
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryName}`}>
                  שם מש'
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryCycle}`}>
                  מחזור
                </p>

                <p className={`${styles.inquiryCell} ${styles.inquiryContent}`}>
                  {mobile ? "נושא" : "נושא פניה"}
                </p>
                <div
                  className={`${styles.inquiryCell} ${styles.inquiryStatus}`}
                >
                  {usersHosenSderot && (
                    <FilterButtonMainPage
                      documentsToFilter={usersHosenSderot}
                      setDocumentsToFilter={setUsersHosenSderonToShow}
                    />
                  )}
                </div>
              </li>
            </ul>
          ) : isLoading ? (
            <div className={styles.text}>
              <Loader />
            </div>
          ) : (
            <p className={styles.text}>
              {isError
                ? "משהו השתבש, אנא נסה שוב מאוחר יותר"
                : "אין פניות חדשות"}
            </p>
          )}
          {usersHosenSderonToShow &&
            usersHosenSderonToShow.map(
              (i, index) =>
                index >= usersHosenSderonLimits.start - 1 &&
                index < usersHosenSderonLimits.end && (
                  <li
                    key={i.Id}
                    className={`${styles.inquiry} ${
                      index % 2 === 0 && styles.inquiry_colored
                    }`}
                    onClick={() => showDocument(i)}
                  >
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryNumber}`}
                    >
                      {i.Id}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryDate}`}
                    >
                      {`${getPrintDate(new Date(i.TimeStamp))}`}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryName}`}
                    >
                      {i.FirstName || ""} {i.LastName || ""}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryCycle}`}
                    >
                      {i.Cycle}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryCategory}`}
                    >
                      {i.QuestionnaireDesc}
                    </p>
                    <button
                      className={`${styles.inquiryCell} button button_white ${styles.btn_take}`}
                    >
                      לקחת
                    </button>
                    <span
                      className={styles.inquiryRRM}
                      style={{
                        backgroundColor:
                          RRMListDefault.find((item) => item.Id === i.RRM)
                            ?.Color || "",
                      }}
                    />
                  </li>
                )
            )}
        </article>
        {usersHosenSderonToShow && usersHosenSderonToShow.length > 0 && (
          <ListPagination
            limits={usersHosenSderonLimits}
            total={usersHosenSderonToShow.length || 0}
            setLimits={setUsersHosenSderonLimits}
            step={limitNumber}
          />
        )}
        <article className={styles.home__section}>
          <h4 className={styles.home__sectionTitle}>פניות חדשות</h4>
          {usersWithOutTherapist && usersWithOutTherapist.length > 0 ? (
            <ul className={styles.inquiries}>
              <li className={`${styles.inquiry} ${styles.inquiryHeader}`}>
                <p className={`${styles.inquiryCell} ${styles.inquiryNumber}`}>
                  מס' פניה
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryDate}`}>
                  תאריך
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryName}`}>
                  שם מש'
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryCycle}`}>
                  מחזור
                </p>

                <p className={`${styles.inquiryCell} ${styles.inquiryContent}`}>
                  {mobile ? "נושא" : "נושא פניה"}
                </p>
                <div
                  className={`${styles.inquiryCell} ${styles.inquiryStatus}`}
                >
                  {usersWithOutTherapist && (
                    <FilterButtonMainPage
                      documentsToFilter={usersWithOutTherapist}
                      setDocumentsToFilter={setUsersWithOutTherapistToShow}
                    />
                  )}
                </div>
              </li>
            </ul>
          ) : isLoading ? (
            <div className={styles.text}>
              <Loader />
            </div>
          ) : (
            <p className={styles.text}>
              {isError
                ? "משהו השתבש, אנא נסה שוב מאוחר יותר"
                : "אין פניות חדשות"}
            </p>
          )}
          {usersWithOutTherapistToShow &&
            usersWithOutTherapistToShow.map(
              (i, index) =>
                index >= usersWithoutTherapistLimits.start - 1 &&
                index < usersWithoutTherapistLimits.end && (
                  <li
                    key={i.Id}
                    className={`${styles.inquiry} ${
                      index % 2 === 0 && styles.inquiry_colored
                    }`}
                    onClick={() => showDocument(i)}
                  >
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryNumber}`}
                    >
                      {i.Id}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryDate}`}
                    >
                      {`${getPrintDate(new Date(i.TimeStamp))}`}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryName}`}
                    >
                      {i.FirstName || ""} {i.LastName || ""}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryCycle}`}
                    >
                      {i.Cycle}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryCategory}`}
                    >
                      {i.QuestionnaireDesc}
                    </p>
                    <button
                      className={`${styles.inquiryCell} button button_white ${styles.btn_take}`}
                    >
                      לקחת
                    </button>
                    <span
                      className={styles.inquiryRRM}
                      style={{
                        backgroundColor:
                          RRMListDefault.find((item) => item.Id === i.RRM)
                            ?.Color || "",
                      }}
                    />
                  </li>
                )
            )}
        </article>
        {usersWithOutTherapistToShow &&
          usersWithOutTherapistToShow.length > 0 && (
            <ListPagination
              limits={usersWithoutTherapistLimits}
              total={usersWithOutTherapistToShow.length || 0}
              setLimits={setUsersWithoutTherapistLimits}
              step={limitNumber}
            />
          )}
        <article className={styles.home__section}>
          <h4 className={styles.home__sectionTitle}>פניות בטיפול שלי</h4>
          {therapistWithUsers && therapistWithUsers.length > 0 ? (
            <ul className={styles.inquiries}>
              <li className={`${styles.inquiry} ${styles.inquiryHeader}`}>
                <p className={`${styles.inquiryCell} ${styles.inquiryNumber}`}>
                  מס' פניה
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryDate}`}>
                  תאריך
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryName}`}>
                  שם מש'
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryCycle}`}>
                  מחזור
                </p>
                <p className={`${styles.inquiryCell} ${styles.inquiryContent}`}>
                  {mobile ? "נושא" : "נושא פניה"}
                </p>
                <div
                  className={`${styles.inquiryCell} ${styles.inquiryStatus}`}
                >
                  {therapistWithUsers && (
                    <FilterButtonMainPage
                      documentsToFilter={therapistWithUsers}
                      setDocumentsToFilter={setTherapistWithUsersToShow}
                    />
                  )}
                </div>
              </li>
            </ul>
          ) : isLoading ? (
            <div className={styles.text}>
              <Loader />
            </div>
          ) : (
            <p className={styles.text}>
              {isError
                ? "משהו השתבש, אנא נסה שוב מאוחר יותר"
                : "אין פניות חדשות"}
            </p>
          )}
          {therapistWithUsersToShow &&
            therapistWithUsersToShow.map(
              (i, index) =>
                index >= therapistWithUsersLimits.start - 1 &&
                index < therapistWithUsersLimits.end && (
                  <li
                    key={i.Id}
                    className={`${styles.inquiry} ${
                      index % 2 === 0 && styles.inquiry_colored
                    }`}
                    onClick={() => showDocument(i)}
                  >
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryNumber}`}
                    >
                      {i.Id}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryDate}`}
                    >
                      {`${getPrintDate(new Date(i.TimeStamp))}`}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryName}`}
                    >
                      {i.FirstName || ""} {i.LastName || ""}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryCycle}`}
                    >
                      {i.Cycle}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryCategory}`}
                    >
                      {i.QuestionnaireDesc}
                    </p>
                    <p
                      className={`${styles.inquiryCell} ${styles.inquiryStatus}`}
                    >
                      {getServerStatusText(i.Status, serverStatuses)}
                    </p>
                    <span
                      className={styles.inquiryRRM}
                      style={{
                        backgroundColor:
                          RRMListDefault.find((item) => item.Id === i.RRM)
                            ?.Color || "",
                      }}
                    />
                  </li>
                )
            )}
        </article>
        {therapistWithUsersToShow && therapistWithUsersToShow.length > 0 && (
          <ListPagination
            limits={therapistWithUsersLimits}
            total={therapistWithUsersToShow?.length || 0}
            setLimits={setTherapistWithUsersLimits}
            step={limitNumber}
          />
        )}
      </div>
    </section>
  );
}

export default MainPage;
