import popupStyles from "./userJoinedChatSuccessPopup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";
import { TTherapistUser } from "../../../services/types/user.types";
import { useNavigate } from "react-router-dom";

function UserJoinedChatSuccessPopup({
  user,
  onClose,
}: IPopupWithoutProps & { user: TTherapistUser }) {
  const navigate = useNavigate();
  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}>
        {user.FirstName} {user.LastName} הצטרף לחדר צ'אט
      </h2>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={popupStyles.popup__img}
      />
      <button
        className={`button hover ${popupStyles.popup__nextBtn}`}
        onClick={() => {
          onClose();
          navigate("/messages");
        }}
      >
        לצ'אט
      </button>
    </article>
  );
}

export default UserJoinedChatSuccessPopup;
