import { IForumThread } from "../types/types";

export const forumThemes = [
  "דיכאון וחרדה",
  "הפרעות שינה",
  "תרופות",
  "מצב רוח",
  "הפרעות אישיות",
];

export const forumExample: {
  id: string;
  title: string;
  avatar: string;
  userName: string;
  date: Date;
  lastMessage: string;
  messages: number;
}[] = [
  {
    id: "123",
    title: "בדיחות על דיכאון, שמחה וששון",
    avatar:
      "https://images.unsplash.com/photo-1539571696357-5a69c17a67c6?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8OXx8cGVvcGxlfGVufDB8fDB8fHww&auto=format&fit=crop&w=600&q=60",
    userName: "הצועד בנעליו",
    date: new Date(),
    lastMessage:
      "בואו נעלה בדיחות על דיכאון, אני ראשון: כומר ורבי נפגשים ברבנות בפתח תקווה ",
    messages: 10,
  },
  {
    id: "345",
    title: "מה עושים כשהמחשבות השחורות עולות לפתע?",
    avatar:
      "https://images.unsplash.com/photo-1507003211169-0a1dd7228f2d?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTZ8fHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60",
    userName: "אחד מהקהל",
    date: new Date(),
    lastMessage:
      "מה עושים כשהמחשבות השחורות עולות לפתע? למעשה, האויב הגדול ביותר שלנו לא נמצא בחוץ אלא בפנים והוא משפיע על איך אנחנו חווים את המציאות בחוץ.",
    messages: 20,
  },
  {
    id: "12389749326",
    title: "בדיחות על דיכאון, שמחה וששון",
    avatar:
      "https://images.unsplash.com/photo-1495216875107-c6c043eb703f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60",
    userName: "הצועד בנעליו",
    date: new Date(),
    lastMessage:
      "מה עושים כשהמחשבות השחורות עולות לפתע? בואו נעלה בדיחות על דיכאון, אני ראשון: כומר ורבי נפגשים ברבנות בפתח תקווה ",
    messages: 4,
  },
];

export const forumThreadExample: IForumThread = {
  id: "1",
  title: "מה עושים כשהמחשבות השחורות עולות לפתע?",
  messages: [
    {
      id: "2",
      user: {
        userName: "אחד מהקהל",
        avatar:
          "https://images.unsplash.com/photo-1495216875107-c6c043eb703f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MjN8fHBlb3BsZXxlbnwwfHwwfHx8MA%3D%3D&auto=format&fit=crop&w=600&q=60",
        date: new Date(),
      },
      message:
        "יש המון רעש בחוץ, בסביבה הקרובה והרחוקה שלנו, אבל הרעש המשמעותי ביותר מגיע מתוכנו דרך המחשבות שלנו. למעשה, האויב הגדול ביותר שלנו לא נמצא בחוץ אלא בפנים והוא משפיע על איך אנחנו חווים את המציאות בחוץ." +
        "זו הבעיה הגדולה של כולנו, וכל כך הרבה שיטות ומומחים ביקשו לפתור אותה, אך עד היום לא נמצא לה, לבעיה שמטרידה את האנושות שולה - פתרון.",
    },
    {
      id: "3",
      user: {
        userName: "גל ברקוביץ",
        avatar:
          "https://media.istockphoto.com/id/1386479313/photo/happy-millennial-afro-american-business-woman-posing-isolated-on-white.webp?b=1&s=170667a&w=0&k=20&c=ahypUC_KTc95VOsBkzLFZiCQ0VJwewfrSV43BOrLETM=",
        date: new Date(),
        tag: "מדריך",
      },
      message:
        "הקושי לשלוט במחשבות הוא גדול, אין בכך ספק." +
        "אנחנו לא יכולים לשלוט בהם אבל אנחנו בהחלט יכולים להגיב בצורה חיובית גם למחשבות שליליות.",
    },
    {
      id: "4",
      user: {
        userName: "יובלי",
        avatar:
          "https://media.istockphoto.com/id/1368424494/photo/studio-portrait-of-a-cheerful-woman.jpg?s=612x612&w=0&k=20&c=ISNDV3ZXeNU6VvDhR7KXFd6y0saq4Eji15cep_Gj8Eg=",
        date: new Date(),
      },
      message:
        "אבל גל, ככל שהמחשבות מתחזקות ככה היכולת שלנו להמשיך ולהגיב בצורה חיובית קטנה... אני מרגישה בלופ שלא נגמר",
      replyTo: "3",
    },
  ],
};
