import styles from "./forumPage.module.css";
import { forumExample, forumThemes } from "../../utils/forum";
import { ChangeEvent, useState } from "react";
import messagesIcon from "../../images/forum_messages_icon.svg";
import { getDate, getTimeFromTimestamp } from "../../utils/utils";
import CreateForumTheme from "../../components/createForumTheme/createForumTheme";
import ForumThread from "../../components/forumThread/forumThread";

function ForumPage() {
  const [active, setActive] = useState<string>(forumThemes[0]);
  const [value, setValue] = useState<string>("");
  const [showCreateNewTheme, setShowCreateNewTheme] = useState<boolean>(false);
  const [thread, setThread] = useState("");

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  return (
    <section className={styles.section}>
      <div className={styles.content}>
        {thread ? (
          <ForumThread onClose={() => setThread("")} />
        ) : !showCreateNewTheme ? (
          <>
            <form
              onSubmit={(e) => {
                e.preventDefault();
                setValue("");
              }}
            >
              <input
                name={"forumSearch"}
                type={"text"}
                className={styles.input}
                value={value}
                onChange={onChangeValue}
              />
            </form>
            <ul className={styles.themes}>
              {forumThemes.map((t, index) => (
                <li
                  key={index}
                  onClick={() => setActive(t)}
                  className={`${styles.themesLi} ${
                    t === active && styles.themeLi_active
                  }`}
                >
                  {t}
                </li>
              ))}
            </ul>
            <button
              type={"button"}
              className={`${styles.btn} hover`}
              onClick={() => {
                setShowCreateNewTheme(true);
              }}
            >
              <span className={styles.plus}>+</span>
              יצירת שרשור חדש
            </button>
            <ul className={styles.forumList}>
              {forumExample.map((m, index) => (
                <li
                  key={index}
                  className={styles.forumLi}
                  onClick={() => setThread(m.id)}
                >
                  <article className={styles.card}>
                    <h3 className={styles.card__title}>{m.title}</h3>
                    <div className={styles.card__user}>
                      <img
                        className={styles.card__avatar}
                        src={m.avatar}
                        alt={m.userName}
                      />
                      <div className={styles.card__data}>
                        <p className={styles.card__name}>{m.userName}</p>
                        <p className={styles.card__date}>
                          {`${getDate(
                            m.date.toString()
                          )}, ${getTimeFromTimestamp(m.date)}`}
                        </p>
                      </div>
                    </div>
                    <p className={styles.text}>{m.lastMessage}</p>
                    <p className={styles.messages}>
                      <img
                        src={messagesIcon}
                        alt={"סמל תגובות"}
                        className={styles.messagesIcon}
                      />
                      {`${m.messages} תגובות `}
                    </p>
                  </article>
                </li>
              ))}
            </ul>
          </>
        ) : (
          <CreateForumTheme
            setShowCreateNewTheme={() => setShowCreateNewTheme(false)}
          />
        )}
      </div>
    </section>
  );
}

export default ForumPage;
