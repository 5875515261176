import styles from "./videoCallWindow.module.css";
import ModalOverlay from "../modalOverlay/ModalOverlay";
import React, { useContext, useEffect } from "react";
import { SocketContext } from "../../../SocketContext";
import incomingCallSound from "../../../sounds/incomingCallSound.wav";
import { useNavigate } from "react-router-dom";

interface IVideoCallWindowProps {
  onClose: () => void;
}

function VideoCallWindow({ onClose }: IVideoCallWindowProps) {
  const navigate = useNavigate();
  const { myVideo, stream, call, callAccepted, startCall, leaveCall } =
    useContext(SocketContext);

  useEffect(() => {
    if (myVideo.current) {
      myVideo.current.srcObject = stream as MediaProvider;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <div className={`${styles.window} `}>
        <audio src={incomingCallSound} autoPlay={true} />
        <div className={styles.videoContainer}>
          <h3 className={styles.header}>מתקשר ל{call.to.name}</h3>
          {/*{stream && (*/}
          {/*  <>*/}
          <video
            playsInline
            // @ts-ignore
            ref={myVideo}
            // muted
            autoPlay
            className={styles.myVideo}
          />
          <div className={styles.userVideoWindow}>
            {startCall && !callAccepted && (
              <div className={styles.outgoingCall}>
                <div className={styles.pulse} />
              </div>
            )}
            {/*{callAccepted && (*/}
            {/*  <video*/}
            {/*    playsInline*/}
            {/*    // muted*/}
            {/*    // @ts-ignore*/}
            {/*    ref={userVideo}*/}
            {/*    autoPlay*/}
            {/*    className={styles.userVideo}*/}
            {/*  />*/}
            {/*)}*/}
            <div className={styles.buttonsBar}>
              <button
                className={`hover ${styles.btn} ${styles.btn_type_flipCamera}`}
              />
              <button
                className={`hover ${styles.btn} ${styles.btn_type_endCall}`}
                onClick={() => {
                  leaveCall();
                  navigate(`/users/${call.to.id}/report`);
                }}
              />
            </div>
          </div>
          {/*  </>*/}
          {/*)}*/}
        </div>
      </div>
      <ModalOverlay onClick={onClose} />
    </>
  );
}

export default VideoCallWindow;
