import supportStyles from "./supportApplicationPage.module.css";
import React, { ChangeEvent, useState } from "react";
import Modal from "../../components/modals/modal/Modal";
import SupportSuccessPopup from "../../components/modals/support_success_popup/support_success_popup";
import { techSupportThemes } from "../../utils/constants";
// import {
//   useSendTechSupportRequestMutation,
//   useSendCustomerServiceRequestMutation,
// } from "../../services/api/requests.api";
// import { useAppSelector } from "../../services/hooks";
// import { errorRequestNumberSelector } from "../../services/selectors/requests.selectors";
import { customerService } from "../../utils/constants";

function SupportApplicationPage({ type }: { type?: string }) {
  const [form, setForm] = useState({ theme: "", question: "" });
  const [openModal, setOpenModal] = useState(false);
  const [showThemes, setShowThemes] = useState(false);

  const title = type === customerService ? "שירות לקוחות" : "תמיכה טכנית";

  const disabled = !form.theme || !form.question;

  // const [sendTechSupportRequest, { isError: isTechSupportError }] =
  //   useSendTechSupportRequestMutation();
  // const [sendCustomerServiceRequest, { isError: isCustomerServiceError }] =
  //   useSendCustomerServiceRequestMutation();
  // const updateError =
  //   // useAppSelector(errorRequestNumberSelector) ??
  //   "משהו השתבש, אנא נסה שוב מאוחר יותר";

  function handleChange(
    evt: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>
  ) {
    const value = evt.target.value;
    setForm({
      ...form,
      [evt.target.name]: value,
    });
  }
  const submitForm = (e: React.FormEvent) => {
    e.preventDefault();
    // type === customerService
    //   ? sendCustomerServiceRequest({ request: form })
    //   : sendTechSupportRequest({ request: form }).then((response) => {
    //       console.log(response);
    //       if ("data" in response && response.data) {
    //         // getUser("");
    //         // setForm({theme: "", question: ""});
    //         // setOpenModal(true);
    //       }
    //     });
    setForm({ theme: "", question: "" });
    setOpenModal(true);
  };
  return (
    <section className={supportStyles.support}>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <SupportSuccessPopup onClose={() => setOpenModal(false)} />
        </Modal>
      )}

      <h2
        className={`${supportStyles.support__title} ${supportStyles.support__title_main}`}
      >
        {title}
      </h2>
      <h3 className={supportStyles.support__title}>פתיחת פנייה ל{title}</h3>
      <p className={supportStyles.support__text}>
        גישה רק לדברים החיוניים ביותר. שדרג לפרימיום וקבל שירותי תמיכה נפשית על
        ידי פסיכולוג מוסמך זמין 24/7, התייעצות בפורום עם מתמודדים כמוך, ועוד
      </p>
      <form className={supportStyles.support__form} onSubmit={submitForm}>
        <label htmlFor="theme" className={supportStyles.support__label}>
          נושא הפנייה
        </label>
        <div
          className={supportStyles.support__inputContainer}
          onClick={(e) => {
            e.stopPropagation();
            setShowThemes(true);
          }}
        >
          <input
            value={form.theme}
            type="text"
            name={"theme"}
            id="theme"
            className={supportStyles.support__input}
            autoFocus={true}
            onChange={handleChange}
          />
          {showThemes && (
            <ul className={supportStyles.support__themes}>
              {techSupportThemes.map((t, index) => (
                <li
                  className={`${supportStyles.support__theme}`}
                  key={index}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowThemes(false);
                    setForm({ ...form, theme: t });
                  }}
                >
                  {t}
                </li>
              ))}
            </ul>
          )}
        </div>
        <label htmlFor="question" className={supportStyles.support__label}>
          תוכן הפנייה
        </label>
        <textarea
          value={form.question}
          name={"question"}
          id="question"
          className={supportStyles.support__textArea}
          onChange={handleChange}
        />
        {/*{(isCustomerServiceError || isTechSupportError) && (*/}
        {/*  <p className={"error"}>{updateError}</p>*/}
        {/*)}*/}
        <button
          type="submit"
          className={`${supportStyles.support__btn} ${
            disabled ? supportStyles.support__btn_disabled : ""
          }`}
        >
          שליחת פניה
        </button>
      </form>
    </section>
  );
}

export default SupportApplicationPage;
