import styles from "./documentsPage.module.css";
import React, { ChangeEvent, useState, useEffect } from "react";
import { filterObjectsByString } from "../../utils/search";
import { filterByDate, filterDocuments, getPrintDate } from "../../utils/utils";
import { Value } from "react-multi-date-picker";
import { ITherapistDocument } from "../../types/types";
import FilterButton from "../../components/filterButton/filterButton";
import { useGetUserDocumentsQuery } from "../../services/api/user.api";
import { useNavigate, useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query";
import { useAppSelector } from "../../services/hooks";
import { documentsSelector } from "../../services/selectors/user.selectors";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";

function DocumentsPage() {
  const { id } = useParams();
  useGetUserDocumentsQuery(id ? id : skipToken);
  const documents = useAppSelector(documentsSelector);
  // const documents = useAppSelector(therapistWithUsersSelector);

  const navigate = useNavigate();

  // const [changeStatus] = useChangeUserDocumentStatusMutation();
  // const { updateDocumentStatus } = useActions();

  const [inputValue, setInputValue] = useState<string>("");
  const [documentsToShow, setDocumentsToShow] =
    useState<ITherapistDocument[]>(documents);
  // const [documentsToShow, setDocumentsToShow] = useState<
  //   TTherapistWithUsers[] | null
  // >(documents);
  const [filter, setFilter] = useState<undefined | string>(undefined);
  const [statusFilter, setStatusFilter] = useState<undefined | string>(
    undefined
  );
  const [dateValue, setDateValue] = useState<Value>(null);

  // const getTotal = (status: string): number => 2; //while no server
  // documents.filter((doc: ITherapistDocument) => doc.status === status).length;
  const getTotal = (status: string): number =>
    documents.filter((doc: ITherapistDocument) => doc.status === status).length;

  // const onStatusChange = (status: number) => {
  //   // updateDocumentStatus({ id: documentId, status });
  //   // id && changeStatus({ id, documentId, status });
  //   setDocumentsToShow(documents);
  // };
  // const filterDocumentsByDate = () => documents;
  // setDocumentsToShow(filterByDate(documents, dateValue));
  const filterDocumentsByDate = () =>
    setDocumentsToShow(filterByDate(documents, dateValue));

  useEffect(() => {
    if (filter) {
      filterDocuments(filter, statusFilter, documents, setDocumentsToShow);
    }
  }, [filter, statusFilter, documents]);

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    setDocumentsToShow(
      () =>
        filterObjectsByString(
          event.target.value,
          documents
        ) as ITherapistDocument[]
    );
  };

  return (
    <article className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <div className={styles.header}>
        <h3 className={styles.title}>כל המסמכים</h3>
        <FilterButton
          filter={filter}
          setFilter={setFilter}
          statusFilter={statusFilter}
          setStatusFilter={setStatusFilter}
          dateValue={dateValue}
          setDateValue={setDateValue}
          filterDocumentsByDate={filterDocumentsByDate}
        />
        <div className={styles.inputContainer}>
          <input
            className={`${styles.input}`}
            type={"text"}
            placeholder={"חיפוש"}
            onChange={onChangeValue}
            value={inputValue}
          />
        </div>
      </div>
      <ul className={styles.tableHeader}>
        <li className={styles.tableHeaderCell}>מס’ פנייה</li>
        <li className={`${styles.tableHeaderCell} ${styles.tableCell_hide}`}>
          סוג מסמך
        </li>
        <li className={styles.tableHeaderCell}>תאריך פתיחה</li>
        <li className={styles.tableHeaderCell}>סטטוס</li>
        <li className={`${styles.tableHeaderCell} ${styles.tableCell_hide}`}>
          צפייה
        </li>
      </ul>
      {documentsToShow && documentsToShow.length > 0 ? (
        <ul className={styles.table}>
          {documentsToShow.map((doc, index) => (
            <li className={styles.tableRow} key={index}>
              <p className={styles.tableCell}>{doc.id || ""}</p>
              <p className={`${styles.tableCell} ${styles.tableCell_hide}`}>
                {doc.category || ""}
              </p>
              <p className={styles.tableCell}>
                {doc?.issued && getPrintDate(doc?.issued)}
              </p>
              <div className={`${styles.tableCell}`}>
                {doc.status}
                {/*<StatusButton document={doc} onStatusChange={() => {}} />*/}
              </div>
              <button
                className={`${styles.tableCell} ${styles.tableCell_hide} ${styles.preview}`}
              />
            </li>
          ))}
        </ul>
      ) : (
        <p className={styles.noDocuments}>לא נמצאו מסמכים</p>
      )}
      <ul className={styles.totalList}>
        <li className={`${styles.totalCard} ${styles.totalCard_type_done}`}>
          <p className={styles.totalNumber}>{getTotal("done")}</p>
        </li>
        <li
          className={`${styles.totalCard} ${styles.totalCard_type_inProgress}`}
        >
          <p className={styles.totalNumber}>{getTotal("inProgress")}</p>
        </li>
        <li
          className={`${styles.totalCard} ${styles.totalCard_type_notAddressed}`}
        >
          <p className={styles.totalNumber}>{getTotal("notYetAddressed")}</p>
        </li>
      </ul>
    </article>
  );
}

export default DocumentsPage;
