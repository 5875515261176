import popupStyles from "./editAvailabilityConfirmationPopup.module.css";
import venchyImg from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";

function EditAvailabilityConfirmationPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={popupStyles.popup}>
      <p className={popupStyles.popup__text}>השינויים נשמרו בהצלחה</p>
      <img
        src={venchyImg}
        alt={"תמונה של וונצ’י"}
        className={popupStyles.popup__img}
      />
      <button
        className={`button hover ${popupStyles.popup__nextBtn}`}
        onClick={onClose}
      >
        אישור
      </button>
    </article>
  );
}

export default EditAvailabilityConfirmationPopup;
