import changePasswordPopupStyles from "./changePasswordSuccessPopup.module.css";
import venchyImg from "../../../images/questionnaire_doctor.png";

interface ChangePasswordSuccessPopupProps {
  onClose: () => void;
  type?: string;
}

function ChangePasswordSuccessPopup({
  onClose,
  type = "password",
}: ChangePasswordSuccessPopupProps) {
  return (
    <article className={changePasswordPopupStyles.popup}>
      <h2 className={changePasswordPopupStyles.popup__title}>
        {type === "password" ? "הוגדרה סיסמה חדשה" : "הוגדרה תמונה חדשה!"}
      </h2>
      <p className={changePasswordPopupStyles.popup__text}>
        תודה על העדכון. אנחנו נעדכן את הפרטים החדשים שהזנת.
      </p>
      <img
        src={venchyImg}
        alt={"תמונה של וונצ’י"}
        className={changePasswordPopupStyles.popup__img}
      />
      <button
        className={`button hover ${changePasswordPopupStyles.popup__btn}`}
        onClick={onClose}
      >
        אישור
      </button>
    </article>
  );
}

export default ChangePasswordSuccessPopup;
