import React from "react";
import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/login/login";
import RestorePasswordPage from "../pages/restore_password/restorePasswordPage";
import LoginSmsCode from "../pages/login/loginSmsCode";
import SigninPage from "../pages/signin/signin";
import ProtectedRoute from "../components/protectedRoute/ProtectedRoute";
import MainContentLayout from "../components/mainContentLayout/mainContentLayout";
import { Category } from "../types/types";
import MainPage from "../pages/main/mainPage";
import UsersPage from "../pages/users/usersPage";
import EditDataPage from "../pages/editData/editDataPage";
import EditPersonalDataPage from "../pages/editPersonalData/editPersonalDataPage";
import DocumentsPage from "../pages/documents/documentsPage";
import ActionReportPage from "../pages/actionReportPage/actionReportPage";
import QuestionReportsPage from "../pages/questionReports/questionReportsPage";
import PrescriptionsPage from "../pages/prescriptions/prescriptions";
import ChatPage from "../pages/chat/ChatPage";
import ProfilePage from "../pages/profile/profile";
import LibraryPage from "../pages/library/library";
import SearchPage from "../pages/search/SearchPage";
import EditAvailabilityMonthlyPage from "../pages/editAvailabilityPage/editAvailabilityMonthlyPage";
import EditAvailabilityPage from "../pages/editAvailabilityPage/editAvailabilityPage";
import CalendarPage from "../pages/calendar/calendar";
import ForumPage from "../pages/forum/forumPage";
import NewPrescriptionPage from "../pages/newPrescription/newPrescriptionPage";
import SupportPage from "../pages/support/SupportPage";
import SupportApplicationPage from "../pages/supportApplication/supportApplicationPage";
import TermsPage from "../pages/terms/termsPage";
import NotFoundPage from "../pages/notFound/notFoundPage";

export const useRoutes = (
  isAuth: boolean,
  changeCategory: (
    e: React.MouseEvent<Element, MouseEvent>,
    cat: Category
  ) => void
) => (
  <Routes>
    <Route path={"/"} element={<LoginPage />} />
    <Route path={"/restore_password"} element={<RestorePasswordPage />} />
    <Route path={"/enter_code"} element={<LoginSmsCode />} />
    <Route path={"/signin"} element={<SigninPage />} />
    <Route
      path={"/main"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Main}
            changeCategory={changeCategory}
          >
            <MainPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <UsersPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/update"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <EditDataPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/personal"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <EditPersonalDataPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/documents"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <DocumentsPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/report/:inquiryId"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <ActionReportPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/report"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <ActionReportPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/questions_reports"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <QuestionReportsPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/users/:id/prescriptions"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <PrescriptionsPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/messages"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <ChatPage changeCategory={changeCategory} />
        </ProtectedRoute>
      }
    />
    <Route
      path={"/profile"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Profile}
            changeCategory={changeCategory}
          >
            <ProfilePage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/library"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Library}
            changeCategory={changeCategory}
          >
            <LibraryPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/calendar/search"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Calendar}
            changeCategory={changeCategory}
          >
            <SearchPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/calendar/edit/monthly"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Calendar}
            changeCategory={changeCategory}
          >
            <EditAvailabilityMonthlyPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/calendar/edit"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Calendar}
            changeCategory={changeCategory}
          >
            <EditAvailabilityPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/calendar"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Calendar}
            changeCategory={changeCategory}
          >
            <CalendarPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/forum"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Forum}
            changeCategory={changeCategory}
          >
            <ForumPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/prescriptions/new/:id"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <NewPrescriptionPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/prescriptions/:id/update/:medicine"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Users}
            changeCategory={changeCategory}
          >
            <NewPrescriptionPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/support"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Support}
            changeCategory={changeCategory}
          >
            <SupportPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/support/apply"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Support}
            changeCategory={changeCategory}
          >
            <SupportApplicationPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route
      path={"/terms"}
      element={
        <ProtectedRoute isAuth={isAuth} redirectPath={`/`}>
          <MainContentLayout
            activeCategory={Category.Terms}
            changeCategory={changeCategory}
          >
            <TermsPage />
          </MainContentLayout>
        </ProtectedRoute>
      }
    />
    <Route path={"*"} element={<NotFoundPage />} />
  </Routes>
);
