import popupStyles from "./updateUserInfoSuccessPopup.module.css";
import venchyImg from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";

function UpdateUserInfoSuccessPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}>עדכון בוצע בהצלחה!</h2>
      <p className={popupStyles.popup__text}>
        תודה על העדכון. אנחנו נעדכן את הפרטים החדשים שהזנת.
      </p>
      <img
        src={venchyImg}
        alt={"תמונה של וונצ’י"}
        className={popupStyles.popup__img}
      />
      <button
        className={`button hover ${popupStyles.popup__nextBtn}`}
        onClick={onClose}
      >
        אישור
      </button>
    </article>
  );
}

export default UpdateUserInfoSuccessPopup;
