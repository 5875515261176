import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./filterButton.module.css";
import filterIcon from "../../images/filter_icon.svg";
import { useAppSelector } from "../../services/hooks";
import { statusesSelector } from "../../services/selectors/user.selectors";
import {
  filterOptionsMain,
  filterOptionsMainWithStatus,
} from "../../utils/constants";
import {
  TTherapistWithUsers,
  TUsersWithOutTherapist,
} from "../../services/types/user.types";
import { isToday, isTTherapistWithUsersArray } from "../../utils/utils";

function FilterButtonMainPage<
  T extends TTherapistWithUsers | TUsersWithOutTherapist
>({
  documentsToFilter,
  setDocumentsToFilter,
}: {
  documentsToFilter: T[] | undefined;
  setDocumentsToFilter: Dispatch<SetStateAction<T[] | undefined>>;
}) {
  const [filtersVisible, setFiltersVisible] = useState(false);
  const statuses = useAppSelector(statusesSelector);
  const [options, setOptions] = useState(filterOptionsMain);
  const [filterValue, setFilterValue] = useState<undefined | string>(undefined);
  const [statusFilter, setStatusFilter] = useState<undefined | number>(
    undefined
  );
  const today = new Date();

  useEffect(() => {
    if (isTTherapistWithUsersArray(documentsToFilter)) {
      setOptions(filterOptionsMainWithStatus);
    }
  }, []);

  useEffect(() => {
    if (filterValue === "clearFilters") {
      setStatusFilter(0);
      setFilterValue(undefined);
    } else if (filterValue === "today") {
      setStatusFilter(0);
      if (documentsToFilter) {
        setDocumentsToFilter(
          documentsToFilter.filter((d) => isToday(d.TimeStamp))
        );
      }
    } else if (filterValue === "week") {
      setStatusFilter(0);
      const startOfCurrentWeek = new Date(today);
      startOfCurrentWeek.setDate(today.getDate() - today.getDay());

      // Calculate the start of the last week (Sunday)
      const startOfLastWeek = new Date(startOfCurrentWeek);
      startOfLastWeek.setDate(startOfCurrentWeek.getDate() - 7);

      documentsToFilter &&
        setDocumentsToFilter(
          documentsToFilter.filter(
            (d) =>
              new Date(d.TimeStamp) >= startOfLastWeek &&
              new Date(d.TimeStamp) <= today
          )
        );
    } else if (filterValue === "month") {
      setStatusFilter(0);
      const startOfCurrentMonth = new Date(
        today.getFullYear(),
        today.getMonth(),
        1
      );
      setDocumentsToFilter(
        documentsToFilter?.filter(
          (d) => new Date(d.TimeStamp) >= startOfCurrentMonth
        )
      );
    } else {
      setDocumentsToFilter(documentsToFilter);
    }
  }, [filterValue]);

  const onStatusChange = (status: number) => {
    setStatusFilter(status);
    if (isTTherapistWithUsersArray(documentsToFilter)) {
      const newValue = documentsToFilter.filter((d) => d.Status === status);
      setDocumentsToFilter(newValue);
    }
  };

  return (
    <div className={`${styles.container} ${styles.center}`}>
      <button
        className={`${styles.btn} ${styles.btn_type_filter} hover`}
        onClick={(e) => {
          e.stopPropagation();
          setFiltersVisible(!filtersVisible);
        }}
      >
        <img className={styles.filterIcon} src={filterIcon} alt="סינון" />
        סינון
      </button>
      {filtersVisible && (
        <ul className={styles.filterOptions}>
          {options.map((f, index) => (
            <li
              key={index}
              className={`${styles.filterOption} ${
                f.value === "status" && styles.filterOption_type_status
              } ${
                filterValue === "status" && styles.filterOption_type_status_open
              }`}
              onClick={(e) => {
                e.stopPropagation();
                setFilterValue(f.value);
                if (f.value !== "status") {
                  setFiltersVisible(false);
                }
              }}
            >
              <input
                type="radio"
                value={f.value}
                id={f.value}
                name="sort"
                className={styles.radio}
                checked={filterValue === f.value}
                onChange={() => {}}
              />
              <label htmlFor={f.value} className={styles.label}>
                {f.label}
              </label>
              {f.value === "status" && (
                <ul className={styles.statusOptions}>
                  {statuses.map((s) => (
                    <li
                      key={s.Id}
                      className={`${styles.filterOption} ${styles.statusOption}`}
                      onClick={() => {
                        onStatusChange(s.Id);
                        setFiltersVisible(false);
                      }}
                    >
                      <input
                        type="radio"
                        value={s.Id}
                        id={s.Id.toString()}
                        name="statusSort"
                        className={`${styles.radio} ${styles.radio_type_status}`}
                        checked={statusFilter === s.Id}
                        onChange={() => {}}
                      />
                      <label htmlFor={s.Id.toString()} className={styles.label}>
                        {s.Desc}
                      </label>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
          <li
            className={styles.filterOption}
            onClick={() => {
              setFilterValue("clearFilters");
              setFiltersVisible(false);
            }}
          >
            <input
              type="radio"
              value="clearFilters"
              id="clearFilters"
              name="sort"
              className={styles.radio}
              checked={filterValue === "clearFilters"}
              onChange={() => {}}
            />
            <label htmlFor="clearFilters" className={styles.label}>
              ללא מסננים
            </label>
          </li>
        </ul>
      )}
      {!!filterValue && <span>!</span>}
    </div>
  );
}

export default FilterButtonMainPage;
