import React, { useState, useContext, useEffect } from "react";
import { SocketContext } from "../../SocketContext";
import { Category } from "../../types/types";
import useMediaQuery from "../../hooks/useMediaQuery";
import { mobileWidth } from "../../utils/constants";
import VideoCallWindow from "../modals/videoCallWindow/videoCallWindow";
import { useAppSelector } from "../../services/hooks";
import { isAuthSelector } from "../../services/selectors/auth.selectors";
import {
  useLazyGetChatAppointmentsQuery,
  useGetUserQuery,
  useLazyGetUsersQuery,
} from "../../services/api/user.api";
import Loader from "../loader/loader";
import Modal from "../modals/modal/Modal";
import useInactivityTimer from "../../hooks/useInactivityTimer";
import InactivityWarning from "../modals/inactivityWarning/inactivityWarning";
import InactivityLogoutPopup from "../modals/inactivityLogoutPopup/inactivityLogoutPopup";
import {
  showUserJoinedModalSelector,
  therapistUsersSelector,
  userSelector,
} from "../../services/selectors/user.selectors";
import useMultipleWebSockets from "../../hooks/useMultipleWebSockets";
import { TTherapistUser } from "../../services/types/user.types";
import UserJoinedChatSuccessPopup from "../modals/userJoinedChatSuccessPopup/userJoinedChatSuccessPopup";
import { useActions } from "../../services/hooks/useActions";
import { useRoutes } from "../../hooks/useRoutes";

function App() {
  const { isLoading: isUserLoading } = useGetUserQuery();

  const mobile = useMediaQuery(mobileWidth);
  const isAuth = useAppSelector(isAuthSelector);
  const therapist = useAppSelector(userSelector);
  const therapistUsers: TTherapistUser[] = useAppSelector(
    therapistUsersSelector
  );
  const showUserJoinedModal = useAppSelector(showUserJoinedModalSelector);

  const [showWarning, setShowWarning] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [userJoined, setUserJoined] = useState<null | TTherapistUser>(null);

  useInactivityTimer(setShowWarning, setShowLogoutModal);

  //get all appointments
  //get all therapist`s users
  const [getAllTherapistUsers] = useLazyGetUsersQuery();
  const [getChatAppointments] = useLazyGetChatAppointmentsQuery();
  useEffect(() => {
    if (isAuth && therapist.id) {
      getAllTherapistUsers(therapist.id);
      getChatAppointments(therapist.id);
    }
  }, [isAuth, therapist.id, getAllTherapistUsers, getChatAppointments]);

  //WS connection
  useMultipleWebSockets();
  const { resetShowUserJoinedModal } = useActions();
  useEffect(() => {
    if (showUserJoinedModal) {
      const user = therapistUsers.find(
        (u) => String(u.Id) === String(showUserJoinedModal)
      );
      user && setUserJoined(user);
    }
  }, [showUserJoinedModal, therapistUsers]);

  //menu
  const [activeCategory, setActiveCategory] = useState(Category.Main);
  const changeCategory = (e: React.MouseEvent, cat: Category) => {
    if (!mobile) {
      e?.stopPropagation();
    }
    setActiveCategory(cat);
  };

  const routes = useRoutes(isAuth, changeCategory);
  //video calls
  const { startCall, setStartCall, callAccepted } = useContext(SocketContext);

  return (
    <>
      {(startCall || callAccepted) && (
        <VideoCallWindow onClose={() => setStartCall(false)} />
      )}
      {isAuth && showWarning && (
        <Modal onClose={() => setShowWarning(false)}>
          <InactivityWarning onClose={() => setShowWarning(false)} />
        </Modal>
      )}
      {showLogoutModal && (
        <Modal onClose={() => setShowLogoutModal(false)}>
          <InactivityLogoutPopup onClose={() => setShowLogoutModal(false)} />
        </Modal>
      )}
      {isAuth && userJoined && (
        <Modal
          onClose={() => {
            setUserJoined(null);
            resetShowUserJoinedModal();
          }}
        >
          <UserJoinedChatSuccessPopup
            user={userJoined}
            onClose={() => {
              setUserJoined(null);
              resetShowUserJoinedModal();
            }}
          />
        </Modal>
      )}
      {isUserLoading ? <Loader /> : routes}
    </>
  );
}

export default App;
