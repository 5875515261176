import styles from "./editPersonalDataPage.module.css";
import React from "react";
import avatarPlaceholder from "../../images/avatar_placeholder.svg";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate, useParams } from "react-router-dom";
import { useAppSelector } from "../../services/hooks";
import { userSelector } from "../../services/selectors/user.selectors";
import { users } from "../../utils/user";

function EditPersonalDataPage() {
  const navigate = useNavigate();
  const testUser = useAppSelector(userSelector);
  const { id } = useParams();
  const user = users.find((u) => u.id === id) || testUser;
  return (
    <article className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <h2 className={styles.title}>משתמש קיים: פרטים אישיים</h2>
      <div className={styles.content}>
        <div className={styles.personal}>
          <h4 className={styles.permissionsTitle}>פרטים אישיים</h4>
          <img
            src={`${user?.avatar ? user.avatar : avatarPlaceholder}`}
            alt="תמונת משתמש"
            className={styles.avatarPlaceholder}
          />
          {user?.account && (
            <p className={styles.accountNumber}>מספר משתמש {user.account}</p>
          )}
          <form className={styles.personalForm}>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="firstName"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                שם פרטי
              </label>
              <input
                type="text"
                name="firstName"
                id="firstName"
                readOnly={true}
                value={user?.firstName || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="lastName"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                שם משפחה
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                readOnly={true}
                value={user?.lastName || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="passport"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                תעודת זהות
              </label>
              <input
                type="text"
                name="passport"
                id="passport"
                readOnly={true}
                value={user?.passport || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="birth"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                תאריך לידה
              </label>
              <input
                type="date"
                name="birth"
                id="birth"
                readOnly={true}
                value={user?.birth || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="country"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                מדינה
              </label>
              <input
                type="text"
                name="country"
                id="country"
                readOnly={true}
                value={user?.country || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="address"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                כתובת מגורים
              </label>
              <input
                type="text"
                name="address"
                id="address"
                readOnly={true}
                value={user?.address || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="email"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                אימייל
              </label>
              <input
                type="text"
                name="email"
                id="email"
                readOnly={true}
                value={user?.email || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
            <div className={styles.personalFormCell}>
              <label
                htmlFor="phone"
                className={`${styles.label} ${styles.label_type_personal}`}
              >
                נייד
              </label>
              <input
                type="text"
                name="phone"
                id="phone"
                readOnly={true}
                value={user?.phone || ""}
                className={`${styles.input} ${styles.input_type_personal}`}
              />
            </div>
          </form>
        </div>
        <div className={styles.permissions}>
          <h4 className={styles.permissionsTitle}>הרשאות מנוי</h4>
          <form className={styles.permissionsForm}>
            <label htmlFor="company" className={`${styles.label}`}>
              שיוך חברה
            </label>
            <input
              type="text"
              name="company"
              id="company"
              value="דיסקונט"
              readOnly={true}
              className={`${styles.input}`}
            />
            <label htmlFor="permission" className={`${styles.label}`}>
              סוג הרשאה
            </label>
            <input
              type="text"
              name="permission"
              id="permission"
              value="מנוי"
              readOnly={true}
              className={`${styles.input}`}
            />
            <label htmlFor="plan" className={`${styles.label}`}>
              סוג מנוי
            </label>
            <input
              type="text"
              name="plan"
              id="plan"
              value="פרימיום"
              readOnly={true}
              className={`${styles.input}`}
            />
            <label htmlFor="status" className={`${styles.label}`}>
              סטטוס
            </label>
            <input
              type="text"
              name="status"
              id="status"
              value="פעיל"
              readOnly={true}
              className={`${styles.input}`}
            />
            <label htmlFor="instructor" className={`${styles.label}`}>
              שיוך מדריך
            </label>
            <input
              type="text"
              name="instructor"
              id="instructor"
              value="ינון איבגי"
              readOnly={true}
              className={`${styles.input}`}
            />
            <label htmlFor="therapist" className={`${styles.label}`}>
              שיוך מטפל
            </label>
            <input
              type="text"
              name="therapist"
              id="therapist"
              value="ינון איבגי"
              readOnly={true}
              className={`${styles.input}`}
            />
          </form>
        </div>
      </div>
      <p className={`${styles.text}`}>
        רק תמיכה טכנית רשאית לערוך נתונים אישיים
      </p>
    </article>
  );
}
export default EditPersonalDataPage;
