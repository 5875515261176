import styles from "./prevencherAnswer.module.css";
import { IContactUsTheme } from "../../utils/categories";
import prevencherAvatar from "../../images/questionnaire_doctor.png";
import React, { useState } from "react";
import Modal from "../modals/modal/Modal";
import PrevencherAnswerPopup from "../modals/prevencherAnswer_popup/prevencherAnswer_popup";

function PrevencherAnswer({ theme }: { theme: IContactUsTheme }) {
  const [openModal, setOpenModal] = useState(false);
  return (
    <li className={styles.answer} onClick={() => setOpenModal(true)}>
      <h3 className={styles.answer__title}>{theme.title}</h3>
      <div className={styles.prevencher}>
        <img
          className={styles.prevencher__avatar}
          src={prevencherAvatar}
          alt={"פריוונצ’ר"}
        />
        <p className={styles.prevencher__name}>פריוונצ’ר</p>
      </div>
      <p className={styles.answer__text}>{theme.text}</p>
      <div onClick={(e) => e.stopPropagation()}>
        {openModal && (
          <Modal prevencherAnswer onClose={() => setOpenModal(false)}>
            <PrevencherAnswerPopup
              onClose={() => setOpenModal(false)}
              theme={theme}
            />
          </Modal>
        )}
      </div>
    </li>
  );
}

export default PrevencherAnswer;
