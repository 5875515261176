import notificationsStyles from "./notifications.module.css";
import { notificationsExample } from "../../utils/constants";
import { useMemo } from "react";
import { useNavigate } from "react-router-dom";

function Notifications({ showNotifications }: { showNotifications: boolean }) {
  const navigate = useNavigate();
  const total = useMemo(
    () => notificationsExample.filter((n) => n.unread),
    [notificationsExample]
  );
  const message = total.length
    ? `${total.length} הודעות חדשות `
    : "אין הודעות חדשות";
  return (
    <div
      className={`${notificationsStyles.notifications} ${
        showNotifications && notificationsStyles.notifications_visible
      }`}
    >
      <div className={notificationsStyles.header}>
        <h3 className={notificationsStyles.title}>התראות</h3>
        <div
          className={`${notificationsStyles.bell} ${
            total.length && notificationsStyles.bell_active
          }`}
        />
      </div>
      <p className={notificationsStyles.text}>{message}</p>
      <ul className={notificationsStyles.list}>
        {notificationsExample.map((n, index) => (
          <li
            key={index}
            className={`${notificationsStyles.notification} ${
              n.unread && notificationsStyles.notification_unread
            }`}
            onClick={() => {
              navigate(`/${n.section}`, {
                replace: true,
                state: { pathname: "notifications" },
              });
            }}
          >
            {n.text}
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Notifications;
