import popupStyles from "./appointmentCard.module.css";
import { IAppointment } from "../../../types/types";
import { getDuration } from "../../../utils/utils";
import { useNavigate } from "react-router-dom";
import React from "react";

interface IAppointmentCardProps {
  appointmentData: IAppointment;
}
function AppointmentCard({ appointmentData }: IAppointmentCardProps) {
  const navigate = useNavigate();

  return (
    <article className={popupStyles.popup}>
      <h2 className={popupStyles.popup__title}>פרטי פגישה</h2>
      <p className={popupStyles.popup__text}>שם המטופל</p>
      <p className={popupStyles.popup__text_big}>{appointmentData.userName}</p>
      <p className={popupStyles.popup__text}>תאריך פגישה</p>
      <p className={popupStyles.popup__text_big}>
        {`${appointmentData.date.getDate()}/${
          appointmentData.date.getMonth() + 1
        }/${appointmentData.date.getFullYear()}`}
      </p>
      <p className={popupStyles.popup__text}>שעת פגישה</p>
      <p className={popupStyles.popup__text_big}>
        {getDuration(appointmentData.date, 50)}
      </p>

      <button
        type={"button"}
        className={`${popupStyles.popup__btn} ${popupStyles.popup__nextBtn} hover`}
        onClick={() =>
          navigate(`/messages`, {
            state: { pathname: appointmentData.userId },
          })
        }
      >
        להתחיל פגישה
      </button>
      <button
        type={"button"}
        className={`${popupStyles.popup__btn} button hover`}
        onClick={() => navigate(`/users/${appointmentData.userId}/personal`)}
      >
        לראות פרטים אישיים
      </button>
    </article>
  );
}

export default AppointmentCard;
