import styles from "./faqItem.module.css";
import { IContactUsTheme } from "../../utils/categories";
import { Dispatch, SetStateAction } from "react";

interface IFaqItemProps {
  theme: IContactUsTheme;
  toggle: object;
  toggleAnswer: (id: number) => void;
  index: number;
  setToggle: Dispatch<SetStateAction<{}>>;
}
function FaqItem({
  theme,
  toggle,
  toggleAnswer,
  index,
  setToggle,
}: IFaqItemProps) {
  return (
    <li
      className={`${styles.faq__li} ${
        toggle[index as keyof typeof setToggle] ? styles.faq__li_long : ""
      }`}
      onClick={() => toggleAnswer(index)}
    >
      <article className={styles.faq__question}>
        <h3 className={styles.faq__questionTitle}>{theme.title}</h3>
        <p className={styles.faq__answer}>{theme.text}</p>
      </article>
    </li>
  );
}

export default FaqItem;
