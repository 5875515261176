import styles from "./editAvailabilityPopup.module.css";
import { IAvailability, ITimeRange } from "../../../types/types";
import React, { useState } from "react";
import {
  decrement,
  hoursRange,
  increment,
  minutesRange,
  newTimeRange,
} from "../../../utils/constants";
import Modal from "../modal/Modal";
import EditAvailabilityConfirmationPopup from "../editAvailabilityConfirmationPopup/editAvailabilityConfirmationPopup";

interface IEditAvailabilityPopupProps {
  day: IAvailability;
  onClose: () => void;
}
function EditAvailabilityPopup({ day, onClose }: IEditAvailabilityPopupProps) {
  const [available, setAvailable] = useState<boolean>(day?.ranges?.length > 0);
  const [ranges, setRanges] = useState<ITimeRange[]>(
    day.ranges.length
      ? day.ranges
      : [
          {
            start: { hours: hoursRange[0], minutes: minutesRange[0] },
            end: { hours: hoursRange[0], minutes: minutesRange[0] },
          },
        ]
  );
  const [toggle, setToggle] = useState({});
  const [openConfirmationPopup, setOpenConfirmationPopup] = useState(false);

  const toggleTimePicker = (id: number) => {
    setToggle({
      ...toggle,
      [id]: !toggle[id as keyof typeof setToggle],
    });
  };

  return (
    <article className={styles.section}>
      <h3 className={styles.title}>{day.title}</h3>
      <div className={styles.availability}>
        <input
          type="checkbox"
          name="available"
          id="available"
          defaultChecked={available}
          onChange={() => {
            setAvailable((prev) => !prev);
          }}
          className={`${styles.toggle} ${
            available ? styles.toggle_active : styles.toggle_disabled
          }`}
        />
        <label htmlFor="available" className={styles.label}>
          {available ? "זמין" : "לא זמין"}
        </label>
      </div>
      <ul className={styles.timePickersList}>
        {ranges.map((r, index) => (
          <li
            key={index}
            className={`${styles.timePickerLi} ${
              toggle[index as keyof typeof setToggle] &&
              styles.timePickerLi_open
            }`}
            onClick={() => {
              toggleTimePicker(index);
              setAvailable(true);
            }}
          >
            <div
              className={`${styles.timeRange} ${
                !available && styles.timeRange_disabled
              }`}
            >
              <div
                className={`${styles.timePicker} ${
                  !available && styles.timePicker_disabled
                }`}
              >
                <span className={styles.hour}>{r.start.hours}</span>
                <span>:</span>
                <span className={styles.hour}>{r.start.minutes}</span>
              </div>
              <p className={styles.timePickerText}>עד</p>
              <div
                className={`${styles.timePicker} ${
                  !available && styles.timePicker_disabled
                }`}
              >
                <span className={styles.hour}>{r.end.hours}</span>
                <span>:</span>
                <span className={styles.hour}>{r.end.minutes}</span>
              </div>
              <button
                className={`${styles.btn} ${styles.btn_type_delete} ${
                  !available && styles.btn_type_delete_disabled
                }`}
                onClick={(e) => {
                  e.stopPropagation();
                  setRanges((prev) => prev.filter((r, i) => i !== index));
                }}
              >
                &#10006;
              </button>
            </div>
            <div className={styles.timeRange}>
              <div className={`${styles.select} `}>
                <button
                  className={`${styles.btn} ${styles.incrementBtn}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRanges((prev) => [
                      ...prev.slice(0, index),
                      {
                        ...prev[index], // Spread the properties of the original object
                        start: {
                          ...prev[index].start, // Spread the properties of the original start object
                          hours: increment(hoursRange, r.start.hours), // Update the hours property
                        },
                      },
                      ...prev.slice(index + 1), // Spread the remaining objects in the array
                    ]);
                  }}
                />
                <button
                  className={`${styles.btn} ${styles.incrementBtn}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRanges((prev) => [
                      ...prev.slice(0, index),
                      {
                        ...prev[index], // Spread the properties of the original object
                        start: {
                          ...prev[index].start, // Spread the properties of the original start object
                          minutes: increment(minutesRange, r.start.minutes), // Update the hours property
                        },
                      },
                      ...prev.slice(index + 1), // Spread the remaining objects in the array
                    ]);
                  }}
                />
                <span className={styles.selectText}>{r.start.hours}</span>
                <span className={styles.selectText}>{r.start.minutes}</span>
                <button
                  className={`${styles.btn} ${styles.decrementBtn}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRanges((prev) => [
                      ...prev.slice(0, index),
                      {
                        ...prev[index], // Spread the properties of the original object
                        start: {
                          ...prev[index].start, // Spread the properties of the original start object
                          hours: decrement(hoursRange, r.start.hours), // Update the hours property
                        },
                      },
                      ...prev.slice(index + 1), // Spread the remaining objects in the array
                    ]);
                  }}
                />
                <button
                  className={`${styles.btn} ${styles.decrementBtn}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRanges((prev) => [
                      ...prev.slice(0, index),
                      {
                        ...prev[index], // Spread the properties of the original object
                        start: {
                          ...prev[index].start, // Spread the properties of the original start object
                          minutes: decrement(minutesRange, r.start.minutes), // Update the hours property
                        },
                      },
                      ...prev.slice(index + 1), // Spread the remaining objects in the array
                    ]);
                  }}
                />
              </div>
              <div />
              <div className={`${styles.select} `}>
                <button
                  className={`${styles.btn} ${styles.incrementBtn}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRanges((prev) => [
                      ...prev.slice(0, index),
                      {
                        ...prev[index], // Spread the properties of the original object
                        end: {
                          ...prev[index].end, // Spread the properties of the original start object
                          hours: increment(hoursRange, r.end.hours), // Update the hours property
                        },
                      },
                      ...prev.slice(index + 1), // Spread the remaining objects in the array
                    ]);
                  }}
                />
                <button
                  className={`${styles.btn} ${styles.incrementBtn}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRanges((prev) => [
                      ...prev.slice(0, index),
                      {
                        ...prev[index], // Spread the properties of the original object
                        end: {
                          ...prev[index].end, // Spread the properties of the original start object
                          minutes: increment(minutesRange, r.end.minutes), // Update the hours property
                        },
                      },
                      ...prev.slice(index + 1), // Spread the remaining objects in the array
                    ]);
                  }}
                />
                <span className={styles.selectText}>{r.end.hours}</span>
                <span className={styles.selectText}>{r.end.minutes}</span>
                <button
                  className={`${styles.btn} ${styles.decrementBtn}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRanges((prev) => [
                      ...prev.slice(0, index),
                      {
                        ...prev[index], // Spread the properties of the original object
                        end: {
                          ...prev[index].end, // Spread the properties of the original start object
                          hours: decrement(hoursRange, r.end.hours), // Update the hours property
                        },
                      },
                      ...prev.slice(index + 1), // Spread the remaining objects in the array
                    ]);
                  }}
                />
                <button
                  className={`${styles.btn} ${styles.decrementBtn}`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setRanges((prev) => [
                      ...prev.slice(0, index),
                      {
                        ...prev[index], // Spread the properties of the original object
                        end: {
                          ...prev[index].end, // Spread the properties of the original start object
                          minutes: decrement(minutesRange, r.end.minutes), // Update the hours property
                        },
                      },
                      ...prev.slice(index + 1), // Spread the remaining objects in the array
                    ]);
                  }}
                />
              </div>
              <button className={`${styles.btn} ${styles.btn_type_delete}`}>
                &#10004;
              </button>
            </div>
          </li>
        ))}
      </ul>
      <button
        className={`${styles.btn} ${styles.btn_type_add} hover`}
        onClick={(e) => {
          e.stopPropagation();
          setAvailable(true);
          setRanges((prev) => [...prev, newTimeRange]);
        }}
      >
        <span className={styles.plus}>+</span>להוסיף טווח זמן
      </button>
      <button
        type={"button"}
        className={`button ${styles.btn_type_submit} hover`}
        onClick={() => {
          setOpenConfirmationPopup(true);
        }}
      >
        שמור
      </button>
      {openConfirmationPopup && (
        <Modal
          onClose={() => {
            setOpenConfirmationPopup(false);
            onClose();
          }}
        >
          <EditAvailabilityConfirmationPopup
            onClose={() => {
              setOpenConfirmationPopup(false);
              onClose();
            }}
          />
        </Modal>
      )}
    </article>
  );
}

export default EditAvailabilityPopup;
