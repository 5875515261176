import { IPrescription, ITherapistDocument } from "../types/types";

export interface IServerStatus {
  Id: number;
  Desc: string;
}
export interface IStatus {
  Id: number;
  text: string;
  class: string;
}

export const statuses: IStatus[] = [
  {
    Id: 4,
    text: "בוצע",
    class: "done",
  },
  {
    Id: 3,
    text: "בטיפול",
    class: "inProgress",
  },
  {
    Id: 2,
    text: "תקוע",
    class: "stuck",
  },
  {
    Id: 1,
    text: "טרם טופל",
    class: "notYetAddressed",
  },
  {
    Id: 0,
    text: "אין מידע",
    class: "notYetAddressed",
  },
];

export const serverStatusesDefault: IServerStatus[] = [
  {
    Id: 1,
    Desc: "ממתין לטיפול",
  },
  {
    Id: 2,
    Desc: "בטיפול",
  },
  {
    Id: 3,
    Desc: "טיפול הושלם",
  },
];
export const getStatusClass = (id: number): string => {
  return id === 3 ? "done" : id === 2 ? "inProgress" : "notYetAddressed";
};
export const getStatusText = (id: number): string => {
  const status = statuses.find((s) => s.Id === id);
  return status ? status.text : "";
};
export const getServerStatusText = (
  id: number,
  statuses: IServerStatus[]
): string => {
  const status = statuses.find((s) => s.Id === id);
  return status ? status.Desc : "ממתין לטיפול";
};

export const therapistDocuments: ITherapistDocument[] = [
  {
    id: "#41566",
    issued: new Date("November 20, 2023 13:15:00"),
    status: "done",
    urgent: true,
    userId: "#25648",
    category: "כללי",
  },
  {
    id: "#86434",
    issued: new Date("November 21, 2023 13:15:00"),
    status: "inProgress",
    urgent: false,
    userId: "#25648",
    category: "כללי",
  },
  {
    id: "#90753",
    issued: new Date("November 30, 2023 13:15:00"),
    status: "done",
    urgent: false,
    userId: "#25648",
    category: "כללי",
  },
  {
    id: "#27809",
    issued: new Date("December 10, 2023 13:15:00"),
    status: "stuck",
    urgent: false,
    userId: "#25648",
    category: "כללי",
  },
  {
    id: "#78903",
    issued: new Date("December 14, 2023 13:15:00"),
    status: "stuck",
    urgent: false,
    userId: "#25648",
    category: "כללי",
  },
  {
    id: "#78905",
    issued: new Date(new Date().setDate(new Date().getDate() - 1)),
    status: "notYetAddressed",
    urgent: true,
    userId: "#25646",
    category: "כללי",
  },
  {
    id: "#78908",
    issued: new Date(),
    status: "notYetAddressed",
    urgent: false,
    userId: "#25649",
    category: "כללי",
  },
  {
    id: "#79907",
    issued: new Date(),
    status: "notYetAddressed",
    urgent: false,
    userId: "#25649",
    category: "כללי",
  },
];
export const prescriptionsExample: IPrescription[] = [
  {
    id: "6532512",
    medicine: "Nocturno",
    doze: "3.75 mg",
    instructions: "לבליעה 1 טבליה 1 פעם ביום (בוקר 1 טבליה) לתקופה של 30 ימים",
    issued: new Date(Date.now() - 86400000), //yesterday
    userId: "1",
    exp: new Date(Date.now() + 86400000 * 10),
    status: "notYetAddressed",
    urgent: false,
    category: "כללי",
  },
  {
    id: "6532512",
    medicine: "Nocturno",
    doze: "3.75 mg",
    instructions: "לבליעה 1 טבליה 1 פעם ביום (בוקר 1 טבליה) לתקופה של 30 ימים",
    issued: new Date("May 2, 2023 12:00:00"),
    userId: "1",
    exp: new Date("July 2, 2023 12:00:00"),
    status: "notYetAddressed",
    urgent: false,
    category: "כללי",
  },
  {
    id: "6532598",
    medicine: "Xanax",
    doze: "250 mcg",
    instructions: "לבליעה 1 טבליה 1 פעם ביום (בוקר 1 טבליה) לתקופה של 30 ימים",
    issued: new Date("May 2, 2023 12:00:00"),
    userId: "1",
    exp: new Date("May 2, 2024 12:00:00"),
    status: "notYetAddressed",
    urgent: false,
    category: "כללי",
  },
];
