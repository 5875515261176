export const getDefaultHeaders = (
  headers: Headers,
  accessToken?: string | undefined
) => {
  headers.set("Accept", "application/json;charset=UTF-8");
  if (!headers.has("Content-Type")) {
    headers.set("Content-Type", "application/json");
  }
  if (accessToken) {
    headers.set("Authorization", `Bearer ${accessToken}`);
  }
  return headers;
};
