import styles from "./rrmLink.module.css";
import { TRRMListEntry } from "../../services/types/user.types";
import React, { useState } from "react";
import Modal from "../modals/modal/Modal";
import RrmModelPopup from "../modals/rrmModel/rrmModelPopup";

function RrmLink({
  score,
  RRMScore,
  scoreList,
  additionalStyles,
}: {
  score: number;
  RRMScore: number;
  scoreList: TRRMListEntry[];
  additionalStyles?: {};
}) {
  let color = scoreList.find((item) => item.Id === RRMScore)?.Color || "#fff";
  const [openModal, setOpenModal] = useState<boolean>(false);
  const showPopup = scoreList[0].Min > 0;
  return (
    <div className={`${styles.link}`} style={additionalStyles}>
      <button className={styles.link__link} style={{ backgroundColor: color }}>
        RRM ({RRMScore})
        {RRMScore >= 5 && (
          <svg
            width="20"
            height="20"
            viewBox="-1.7 0 20.4 20.4"
            xmlns="http://www.w3.org/2000/svg"
            className="cf-icon-svg"
            fill="#fff"
          >
            <path d="M16.406 10.283a7.917 7.917 0 1 1-7.917-7.917 7.916 7.916 0 0 1 7.917 7.917zM9.48 14.367a1.003 1.003 0 1 0-1.004 1.003 1.003 1.003 0 0 0 1.004-1.003zM7.697 11.53a.792.792 0 0 0 1.583 0V5.262a.792.792 0 0 0-1.583 0z" />
          </svg>
        )}
      </button>
      {showPopup ? (
        <p
          onClick={() => {
            setOpenModal(true);
          }}
          className={styles.link__text}
        >
          מידע על המודל
        </p>
      ) : (
        <p className={`${styles.link__text} ${styles.text}`}>RRM משוקלל</p>
      )}
      {openModal && scoreList && (
        <Modal prevencherAnswer={true} onClose={() => setOpenModal(!openModal)}>
          <RrmModelPopup
            RRMScore={RRMScore}
            score={score}
            scoreList={scoreList}
          />
        </Modal>
      )}
    </div>
  );
}

export default RrmLink;
