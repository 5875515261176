import styles from "./newPrescription.module.css";
import React, { useEffect, useState } from "react";
import { prescriptionFormInitialState } from "../../utils/constants";
import { useNavigate, useParams } from "react-router-dom";
import Modal from "../../components/modals/modal/Modal";
import SendNewPrescriptionSuccessPopup from "../../components/modals/sendNewPrescriptionSuccessPopup/sendNewPrescriptionSuccessPopup";
import NewPrescriptionForm from "../../components/forms/newPrescriptionForm/newPrescriptionForm";
import UserInfo from "../../components/userInfo/userInfo";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useAppSelector } from "../../services/hooks";
import { therapistWithUsersSelector } from "../../services/selectors/user.selectors";
import { TTherapistWithUsers } from "../../services/types/user.types";

function NewPrescriptionPage() {
  const { id: userId } = useParams();
  const { medicine } = useParams();
  const navigate = useNavigate();

  const users = useAppSelector(therapistWithUsersSelector);
  const user = users?.find(
    (u: TTherapistWithUsers) => u.Id.toString() === userId
  );
  const [openModal, setOpenModal] = useState(false);
  const [forms, setForms] = useState([prescriptionFormInitialState]);
  const onSubmit = () => {
    setOpenModal(true);
    setForms([prescriptionFormInitialState]);
  };
  useEffect(() => {
    if (medicine) {
      setForms((prev) =>
        prev.map((f, index) => {
          return index === 0 ? { ...f, medicine: medicine } : f;
        })
      );
    }
  }, [medicine]);
  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"למסך הקודם"}
        onClick={() => navigate(-1)}
        position={"center"}
      />
      <h2 className={styles.title}>מרשם חדש</h2>
      {openModal && (
        <Modal onClose={() => setOpenModal(false)}>
          <SendNewPrescriptionSuccessPopup
            onClose={() => setOpenModal(false)}
          />
        </Modal>
      )}
      {user && <UserInfo user={user} />}
      {forms.map((f, index) => (
        <NewPrescriptionForm
          key={index}
          form={f}
          setForm={(value, name: string) =>
            setForms((prev) =>
              prev.map((f, i) => (i === index ? { ...f, [name]: value } : f))
            )
          }
        />
      ))}
      <button
        className={`button button_white ${styles.submitBtn}`}
        type={"button"}
        onClick={() => {
          setForms((prev) => [...prev, prescriptionFormInitialState]);
        }}
      >
        להוסיף עוד תרופה
      </button>
      <button
        className={`button ${styles.submitBtn}`}
        type={"button"}
        onClick={onSubmit}
      >
        שלח
      </button>
    </section>
  );
}

export default NewPrescriptionPage;
