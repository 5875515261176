import React, {
  createContext,
  useState,
  useRef,
  ReactNode,
  Dispatch,
  SetStateAction,
} from "react";
// import Peer from "simple-peer";
import { ICall } from "./types/ws.types";
// import { getSocket } from "./services/api/ws.api";
// import { useAppSelector } from "./services/hooks";
// import { userSelector } from "./services/selectors/user.selectors";

export interface ChatSocketCtxState {
  call: ICall;
  setCall: (call: ICall) => void;
  callAccepted: boolean;
  setCallAccepted: Dispatch<SetStateAction<boolean>>;
  callEnded: boolean;
  setCallEnded: Dispatch<SetStateAction<boolean>>;
  openModalCallEnded: boolean;
  setOpenModalCallEnded: Dispatch<SetStateAction<boolean>>;
  stream: MediaStream | undefined;
  setStream: Dispatch<SetStateAction<MediaStream | undefined>>;
  mySocketId: string;
  setMySocketId: (id: string) => void;
  myVideo: React.MutableRefObject<HTMLVideoElement | undefined>;
  userVideo: React.MutableRefObject<HTMLVideoElement | undefined>;
  callUser: (to: { name: string; id: string }) => void;
  leaveCall: () => void;
  answerCall: () => void;
  startCall: boolean;
  setStartCall: Dispatch<SetStateAction<boolean>>;
}

const SocketContext = createContext<ChatSocketCtxState>(
  {} as ChatSocketCtxState
);
export const videoCallInitialState: ICall = {
  isReceivingCall: false,
  from: {
    name: "פרופסור יעקב הלברטל",
    title: "פסיכיאטר",
    avatar:
      "https://media.istockphoto.com/id/1405522719/photo/man-sitting-indoor-looks-at-camera-participate-in-live-videoconference.jpg?s=1024x1024&w=is&k=20&c=R84ypnGiPl93LQbSM-nhyBr5nlFNc6deX7d-uKqNOiQ=",
    callerSocketId: "",
  },
  signal: { type: "offer" },
  to: { name: "", id: "" },
};

const ContextProvider = ({ children }: { children: ReactNode }) => {
  const [callAccepted, setCallAccepted] = useState(false);
  const [callEnded, setCallEnded] = useState(false);
  const [stream, setStream] = useState<MediaStream>();
  const [call, setCall] = useState<ICall>(videoCallInitialState);
  const [mySocketId, setMySocketId] = useState("");
  const [startCall, setStartCall] = useState(false);
  const [openModalCallEnded, setOpenModalCallEnded] = useState(false);

  const myVideo = useRef<HTMLVideoElement>();
  const userVideo = useRef<HTMLVideoElement>();
  // const connectionRef = useRef<Peer.Instance | null>();
  // const user = useAppSelector(userSelector);

  const answerCall = () => {
    // const socket = getSocket();
    // setCallAccepted(true);
    //
    // const peer = new Peer({ initiator: false, trickle: false, stream });
    // console.log(1000, peer, call);
    //
    // peer.on("signal", (data) => {
    //   console.log(1001, data, call, mySocketId);
    //   socket.emit("answerCall", { signal: data, to: call.from.callerSocketId });
    // });
    //
    // peer.on("stream", (currentStream) => {
    //   console.log(1002, currentStream);
    //   if (userVideo.current) userVideo.current.srcObject = currentStream;
    // });
    //
    // peer.signal(call.signal);
    //
    // connectionRef.current = peer;
  };

  const callUser = (to: { name: string; id: string }) => {
    // const socket = getSocket();
    // const peer = new Peer({ initiator: true, trickle: false, stream });
    setCall({ ...videoCallInitialState, to });

    // peer.on("signal", (data) => {
    //   socket.emit("callUser", {
    //     userToCall: to.id,
    //     signalData: data,
    //     from: mySocketId,
    //     name: user.firstName,
    //   });
    // });
    //
    // peer.on("stream", (currentStream) => {
    //   if (userVideo.current) userVideo.current.srcObject = currentStream;
    // });
    //
    // socket.on("callAccepted", (signal) => {
    //   setCallAccepted(true);
    //   console.log(2000, call);
    //   peer.signal(signal);
    // });

    // connectionRef.current = peer;
  };

  const leaveCall = () => {
    // const socket = getSocket();
    //
    setCallEnded(true);
    setCallAccepted(false);
    setStartCall(false);
    setOpenModalCallEnded(true);
    // if (connectionRef.current) connectionRef.current = null;
    setCall(videoCallInitialState);
    // console.log(3333);
    // // window.location.reload();
    // socket.emit("endCall", {
    //   by: mySocketId,
    //   name: user.firstName,
    // });
  };

  return (
    <SocketContext.Provider
      value={{
        call,
        setCall,
        callAccepted,
        myVideo,
        userVideo,
        stream,
        setStream,
        callEnded,
        mySocketId,
        setMySocketId,
        callUser,
        leaveCall,
        answerCall,
        startCall,
        setStartCall,
        setCallEnded,
        setCallAccepted,
        openModalCallEnded,
        setOpenModalCallEnded,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

export { ContextProvider, SocketContext };
