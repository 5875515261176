export function filterObjectsByString<T extends object>(
  inputString: string,
  arrayOfObjects: T[]
): T[] {
  return arrayOfObjects.filter((obj) => hasMatches(obj, inputString));
}

function hasMatches(object: object, searchString: string): boolean {
  const searchWords = searchString.toLowerCase().split(" "); // Split the search string into words and convert to lowercase

  // Check if every word in the search string is found in the object values
  return searchWords.every((word) =>
    Object.values(object).some(
      (value) => typeof value === "string" && value.toLowerCase().includes(word) // Convert object values to lowercase before comparison
    )
  );
}
