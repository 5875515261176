import popupStyles from "../generalSuccessPopup/generalSuccessPopup.module.css";
import doctor from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";
import { Link } from "react-router-dom";

function InactivityLogoutPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={popupStyles.popup}>
      <img
        src={doctor}
        alt="תמונה של וונצ’י"
        className={popupStyles.popup__img}
      />
      <p
        className={`${popupStyles.popup__title} ${popupStyles.popup__title_small}`}
      >
        הודעה: יציאה מהחשבון עקב חוסר פעילות.
      </p>
      <p className={popupStyles.popup__text}>
        יצאת מהחשבון שלך באופן אוטומטי עקב חוסר פעילות. צעד זה נועד להגן על
        המידע האישי שלך ולהבטיח את אבטחת החשבון שלך. יציאה מהחשבון לאחר תקופה של
        חוסר פעילות מסייעת במניעת גישה לא מורשית לחשבונך במקרה שבו תשאיר את
        המכשיר ללא השגחה או תשכח לצאת מהחשבון באופן ידני. תודה על ההבנה ועל
        הסיוע בשמירה על אבטחת החשבון שלך{" "}
      </p>
      <Link
        to={"/"}
        className={`button hover ${popupStyles.popup__nextBtn}`}
        onClick={onClose}
      >
        חזרה לאתר
      </Link>
    </article>
  );
}

export default InactivityLogoutPopup;
