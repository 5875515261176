import mainContentLayoutStyles from "../../components/mainContentLayout/mainContentLayout.module.css";
import ChatSideBar from "../../components/chatSidebar/chatSideBar";
import { mobileWidth } from "../../utils/constants";
import Sidebar from "../../components/sidebar/sidebar";
import React, { useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import Chat from "../../components/chat/chat";
import { Category } from "../../types/types";
import { menuCategories } from "../../utils/categories";

interface IChatPageProps {
  changeCategory: (e: React.MouseEvent, cat: Category) => void;
}

function ChatPage({ changeCategory }: IChatPageProps) {
  const [openMenu, setOpenMenu] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const mobile = useMediaQuery(mobileWidth);

  const toggleMenu = () => {
    setOpenMenu(!openMenu);
  };
  const hideNotifications = () => {
    if (showNotifications) setShowNotifications(false);
  };

  return (
    <main
      className={`${mainContentLayoutStyles.main} ${
        mainContentLayoutStyles.main_chat
      } ${!openMenu && mainContentLayoutStyles.main_chat_small} `}
    >
      {/*<Notifications showNotifications={showNotifications} />*/}
      <div
        className={`${mainContentLayoutStyles.overlay} ${
          mobile && openMenu ? mainContentLayoutStyles.overlay_visible : ""
        }`}
      />
      <section
        className={`${mainContentLayoutStyles.mainContent} ${mainContentLayoutStyles.mainContent_chat}`}
        onClick={hideNotifications}
      >
        <div
          className={`${mainContentLayoutStyles.header} ${mainContentLayoutStyles.header_chat}`}
        >
          <h1 className={`${mainContentLayoutStyles.mainContent__title}`}>
            {menuCategories[Category.Messages]}
          </h1>
          {/*<button*/}
          {/*  className={`${mainContentLayoutStyles.mainContent__bell} ${*/}
          {/*    showNotifications &&*/}
          {/*    mainContentLayoutStyles.mainContent__bell_invisible*/}
          {/*  }`}*/}
          {/*  onClick={() => {*/}
          {/*    setShowNotifications(!showNotifications);*/}
          {/*  }}*/}
          {/*/>*/}
          <button
            type="button"
            onClick={toggleMenu}
            className={mainContentLayoutStyles.burger}
          />
        </div>
        <Chat openMenu={openMenu} />
      </section>
      {!mobile && <ChatSideBar openMenu={openMenu} />}
      <Sidebar
        changeCategory={changeCategory}
        activeCategory={Category.Messages}
        openMenu={openMenu}
        toggleMenu={toggleMenu}
      />
    </main>
  );
}

export default ChatPage;
