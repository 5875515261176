import searchPageStyles from "./searchPage.module.css";
import React, { useState } from "react";
import { ITherapist } from "../../types/types";
// import MakeAppointment from "../../components/makeAppointment/makeAppointment";

function SearchPage() {
  const [search, setSearch] = useState<"type" | "list">("type");
  const [value, setValue] = useState<string>("");
  const [fieldIndex, setFieldIndex] = useState<number>(0);
  const [variants, setVariants] = useState<string[]>([]);
  const [therapists, setTherapists] = useState<ITherapist[]>([]);

  const onInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTherapists([]);
    setValue(e.target.value);
  };

  const findTherapists = () => {
    // const t = testData.therapists.filter((t) => t.fields.indexOf(value) > 0);
    // setTherapists(testData.therapists);
    setVariants([]);
  };
  return (
    <section className={searchPageStyles.page}>
      <div className={searchPageStyles.section}>
        <div className={searchPageStyles.search}>
          <h2 className={searchPageStyles.search__title}>חיפוש עצמאי</h2>
          <input
            name={"search"}
            id={"search"}
            className={searchPageStyles.search__input}
            onChange={onInputChange}
            value={value}
          />
          {variants.length > 0 && (
            <ul className={searchPageStyles.search__variants}>
              {variants.map((v, index) => (
                <li
                  key={index}
                  className={searchPageStyles.search__variant}
                  onClick={() => {
                    setValue(v);
                    setFieldIndex(index);
                  }}
                >
                  {v}
                </li>
              ))}
            </ul>
          )}
          {therapists.length > 0 ? (
            <div className={searchPageStyles.result}>
              <p
                className={`${searchPageStyles.section__text} ${searchPageStyles.section__text_therapists}`}
              >
                {`${therapists.length} מומחים נמצאו עבור:`}
              </p>
              <p className={searchPageStyles.search__title}>{value}</p>
              <ul className={searchPageStyles.therapists}>
                {/*{therapists.map((t, index) => (*/}
                {/*  <TherapistCard*/}
                {/*    key={index}*/}
                {/*    therapist={t}*/}
                {/*    setTherapist={setTherapist}*/}
                {/*  />*/}
                {/*))}*/}
              </ul>
            </div>
          ) : (
            <>
              {/*{(isError || fetchTherapistsIsError) && (*/}
              {/*  <p className={"error"}>*/}
              {/*    משהו השתבש, אנא נסה שוב מאוחר יותר*/}
              {/*  </p>*/}
              {/*)}*/}
              <button
                type={"button"}
                className={`button ${searchPageStyles.section__btn} ${searchPageStyles.section__btn_search} hover`}
                onClick={() => {
                  findTherapists();
                }}
              >
                חיפוש
              </button>
              <div className={searchPageStyles.bottomBar}>
                <p className={searchPageStyles.bottomBar__title}>או</p>
                <button
                  type={"button"}
                  className={`button ${searchPageStyles.section__btn} ${searchPageStyles.section__btn_bottomBar} hover`}
                >
                  לרשימה של המטופלים שלי
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </section>
  );
}

export default SearchPage;
