import chatSidebarStyles from "./chatSidebar.module.css";
import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import avatarPlaceholder from "../../images/avatar_placeholder.svg";
import { useAppSelector } from "../../services/hooks";
import {
  chatConnectionsSelector,
  chatUsersSelector,
  currentChatUserIdSelector,
  therapistUsersSelector,
} from "../../services/selectors/user.selectors";
import { getChatUserName } from "../../utils/utils";
import { TTherapistUser } from "../../services/types/user.types";
import { useActions } from "../../services/hooks/useActions";
import CloseChatButton from "../closeChatButton/closeChatButton";

interface ChatSideBarProps {
  setOpenSideBar?: Dispatch<SetStateAction<boolean>>;
  openMenu: boolean;
}

const ChatSideBar: React.FC<ChatSideBarProps> = ({
  openMenu,
  setOpenSideBar,
}) => {
  const chatConnections = useAppSelector(chatConnectionsSelector);
  const therapistUsers: TTherapistUser[] = useAppSelector(
    therapistUsersSelector
  );
  const currentChatUserId = useAppSelector(currentChatUserIdSelector);
  const chatUsers = useAppSelector(chatUsersSelector);
  const currentChatUser = chatUsers.find((u) => u.userId === currentChatUserId);
  const { setCurrentChatUserId } = useActions();

  const [users, setUsers] = useState(chatUsers);
  const [showActiveAppointments, setShowActiveAppointments] = useState(true);

  useEffect(() => {
    if (showActiveAppointments) {
      const activeUsers = chatUsers.filter((u) => u.appointment?.Active);
      setUsers(activeUsers);
      activeUsers[0] && setCurrentChatUserId(activeUsers[0].userId);
    } else {
      setUsers(chatUsers);
      chatUsers.length && setCurrentChatUserId(chatUsers[0].userId);
    }
  }, [showActiveAppointments, chatUsers, setCurrentChatUserId]);

  return (
    <section
      className={`${chatSidebarStyles.sidebar} ${
        !openMenu && chatSidebarStyles.sidebar_closed
      }`}
    >
      <div className={chatSidebarStyles.sidebar__buttons}>
        <button
          className={`${chatSidebarStyles.sidebar__btn} ${
            !showActiveAppointments && chatSidebarStyles.sidebar__btn_active
          }`}
          onClick={() => setShowActiveAppointments(false)}
        >
          ארכיון
        </button>
        <button
          className={`${chatSidebarStyles.sidebar__btn} ${
            showActiveAppointments && chatSidebarStyles.sidebar__btn_active
          }`}
          onClick={() => setShowActiveAppointments(true)}
        >
          שיחות
        </button>
      </div>
      <input
        type="text"
        placeholder="חיפוש"
        className={chatSidebarStyles.sidebar__input}
      />
      {users.length > 0 ? (
        <ul
          className={`${chatSidebarStyles.sidebar__therapists} ${chatSidebarStyles.scrollable} scroll`}
        >
          {users.map((u) => (
            <li
              key={u.userId}
              className={`${chatSidebarStyles.sidebar__li} ${
                u.userId === currentChatUser?.userId &&
                chatSidebarStyles.sidebar__li_active
              }`}
              onClick={() => {
                setCurrentChatUserId(u.userId);
                if (setOpenSideBar) setOpenSideBar(false);
              }}
            >
              <div
                className={`${chatSidebarStyles.sidebar__online} ${
                  chatConnections.find((con) => con.userId === u.userId)
                    ?.appointment?.online &&
                  chatSidebarStyles.sidebar__online_active
                }`}
              >
                <img
                  className={chatSidebarStyles.sidebar__avatar}
                  src={avatarPlaceholder}
                  alt={getChatUserName(therapistUsers, u.userId)}
                />
              </div>
              <div className={chatSidebarStyles.sidebar__therapist}>
                <p className={chatSidebarStyles.sidebar__name}>
                  {getChatUserName(therapistUsers, u.userId)}
                </p>
              </div>
              {u.appointment?.Active && (
                <CloseChatButton appointment={u.appointment} />
              )}
            </li>
          ))}
        </ul>
      ) : (
        <p className={chatSidebarStyles.noAppointmentsText}>טרם קבעת פגישות</p>
      )}
    </section>
  );
};

export default ChatSideBar;
