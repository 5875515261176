import changePasswordPopupStyles from "./changeAvatarPopup.module.css";
import React, { useState } from "react";
import { IPopupWithoutProps } from "../../../types/types";
import ChangePasswordSuccessPopup from "../changePasswordSuccessPopup/changePasswordSuccessPopup";
import { useFormik } from "formik";
import { changeAvatarValidationSchema } from "../../../utils/validation";
import loginStyles from "../../../pages/login/login.module.css";
import {
  useLazyGetUserQuery,
  useUpdateUserMutation,
} from "../../../services/api/user.api";
import { useAppSelector } from "../../../services/hooks";
import { errorUserSelector } from "../../../services/selectors/auth.selectors";
import { userSelector } from "../../../services/selectors/user.selectors";

function ChangeAvatarPopup({ onClose }: IPopupWithoutProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);

  const [updateUser, { isError }] = useUpdateUserMutation();
  const [getUser] = useLazyGetUserQuery();
  const updateError = useAppSelector(errorUserSelector);
  const user = useAppSelector(userSelector);

  const handleSubmit = async (avatar: string) => {
    const response = await updateUser({
      body: [
        {
          op: "replace",
          path: `/avatar`,
          value: avatar,
        },
      ],
      id: user.id,
    });
    if ("data" in response && response.data) {
      setOpenModal(true);
      getUser();
    }
  };

  const formik = useFormik({
    initialValues: {
      link: "",
    },
    validationSchema: changeAvatarValidationSchema,
    onSubmit: (values) => {
      // Handle form submission
      handleSubmit(values.link);
    },
  });

  return openModal ? (
    <ChangePasswordSuccessPopup onClose={onClose} type={"avatar"} />
  ) : (
    <article className={changePasswordPopupStyles.popup}>
      <h2 className={changePasswordPopupStyles.popup__title}>
        שינוי תמונת פרופיל
      </h2>
      <form
        id="updateAvatar"
        onSubmit={formik.handleSubmit}
        className={changePasswordPopupStyles.popup__form}
      >
        <label
          htmlFor={"link"}
          className={changePasswordPopupStyles.popup__label}
        >
          הזן קישור לתמונת הפרופיל שלך
        </label>
        <input
          type="text"
          name={"link"}
          id={"link"}
          placeholder={"הזן קישור"}
          value={formik.values.link}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          className={`${changePasswordPopupStyles.popup__input} ${
            formik.errors.link && changePasswordPopupStyles.popup__input_invalid
          }`}
        />
        {formik.errors.link && (
          <p className={`${loginStyles.login__invalid_message}`}>
            {formik.errors.link}
          </p>
        )}
        {isError && (
          <p className={`${loginStyles.login__invalid_message}`}>
            {updateError}
          </p>
        )}
        <button
          type="submit"
          form="updateAvatar"
          disabled={!formik.isValid || formik.isSubmitting}
          className={`button hover ${
            changePasswordPopupStyles.popup__submitBtn
          } ${
            (!formik.isValid || formik.isSubmitting) &&
            changePasswordPopupStyles.popup__submitBtn_disabled
          }`}
        >
          לשינוי התמונה
        </button>
      </form>
    </article>
  );
}

export default ChangeAvatarPopup;
