import signinFormStyles from "./signinForms.module.css";
import React, { useEffect, useState } from "react";
import { ISigninFormProps } from "../../../types/signinTypes";
import { useNavigate } from "react-router-dom";
import { passwordCheck } from "../../../utils/utils";
import Modal from "../../modals/modal/Modal";
import SignupSuccessPopup from "../../modals/signup_success_popup/signup_success_popup";
import { authApi } from "../../../services/api/auth.api";
import { useAppSelector } from "../../../services/hooks";
import { errorUserSelector } from "../../../services/selectors/auth.selectors";

function CreatePasswordForm({ state, setState }: ISigninFormProps) {
  const navigate = useNavigate();

  const [openModal, setOpenModal] = useState(false);
  const [repeatPassword, setRepeatPassword] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [error, setError] = useState("");

  const signInError = useAppSelector(errorUserSelector);

  const [register, { isError, isLoading }] = authApi.useRegisterMutation();

  const closeModal = () => {
    navigate("/main");
    setOpenModal(false);
  };

  function handleChange(evt: React.ChangeEvent<HTMLInputElement>) {
    const value = evt.target.value;
    setState({
      ...state,
      [evt.target.name]: value,
    });
  }
  const onRepeatPasswordChange = (evt: React.ChangeEvent<HTMLInputElement>) => {
    setRepeatPassword(evt.target.value);
  };
  const submitForm = async (e: React.FormEvent) => {
    e.preventDefault();
    const response = await register(state);
    if ("data" in response && response.data.success) {
      setOpenModal(true);
    }
  };
  useEffect(() => {
    setError("");
    if (state.password.length > 0 && state.password.length < 6) {
      setError("הסיסמה חייבת להיות לפחות 6 תווים");
    } else if (
      repeatPassword.length > 0 &&
      (repeatPassword.length < 6 ||
        repeatPassword.length !== state.password.length)
    ) {
      setDisabled(true);
      setError("הסיסמאות לא זהות");
      return;
    }
    if (repeatPassword.length > 0 && repeatPassword === state.password) {
      setError("");
      setDisabled(false);
    }
  }, [repeatPassword, state.password]);
  return (
    <form
      className={signinFormStyles.signin_private__form}
      onSubmit={submitForm}
    >
      <label
        htmlFor="password"
        className={signinFormStyles.signin_private__label}
      >
        סיסמה חדשה
      </label>
      <input
        type="password"
        className={signinFormStyles.signin_private__input}
        name="password"
        value={state.password}
        onChange={handleChange}
        required
        minLength={6}
      />
      <label
        htmlFor="repeatPassword"
        className={signinFormStyles.signin_private__label}
      >
        הקלד את הסיסמה שוב
      </label>
      <input
        type="password"
        className={signinFormStyles.signin_private__input}
        name="repeatPassword"
        value={repeatPassword}
        onChange={onRepeatPasswordChange}
        required
        minLength={6}
      />
      {state.password && (
        <>
          <div className={signinFormStyles.signin_private__strongContainer}>
            {[1, 2, 3].map((level, index) => (
              <span
                key={index}
                className={`${signinFormStyles.signin_private__strong} ${
                  index < passwordCheck(state.password).strength &&
                  signinFormStyles.signin_private__strong_active
                }`}
              />
            ))}
          </div>
          <p className={signinFormStyles.signin_private__strongText}>
            חוזק סיסמא: {passwordCheck(state.password).val}
          </p>
        </>
      )}
      <p className={signinFormStyles.signin_private__note}>הגדרת סיסמה חזקה:</p>
      <p className={signinFormStyles.signin_private__note}>
        באורך של לפחות 6 תווים אבל עדיף 8 או יותר.
      </p>
      <p className={signinFormStyles.signin_private__note}>
        שילוב של אותיות, מספרים וסמלים
      </p>
      {error && (
        <p
          className={`${signinFormStyles.signin_private__invalid_message} ${signinFormStyles.signin_private__invalid_message_visible}`}
        >
          {error}
        </p>
      )}
      {isError && (
        <p
          className={`${signinFormStyles.signin_private__invalid_message} ${signinFormStyles.signin_private__invalid_message_visible}`}
        >
          {signInError}
        </p>
      )}
      <button
        type="submit"
        disabled={disabled}
        className={`${signinFormStyles.signin_private__btn} ${
          disabled ? signinFormStyles.signin_private__btn_disabled : "hover"
        }`}
      >
        {isLoading ? "שליחה" : "המשך"}
      </button>
      {openModal && (
        <Modal onClose={closeModal}>
          <SignupSuccessPopup onClose={closeModal} />
        </Modal>
      )}
    </form>
  );
}

export default CreatePasswordForm;
