import tooltipStyles from "./tooltip.module.css";

function Tooltip({
  children,
  isShown,
  position,
}: {
  children: string;
  isShown: boolean;
  position?: string;
}) {
  return (
    <span
      className={`${tooltipStyles.tooltip} ${
        position === "center" && tooltipStyles.tooltip_position_center
      }
      ${isShown && tooltipStyles.tooltip_visible}`}
    >
      {children}
    </span>
  );
}

export default Tooltip;
