import styles from "./sendNewPrescriptionSuccessPopup.module.css";
import venchyImg from "../../../images/questionnaire_doctor.png";
import { IPopupWithoutProps } from "../../../types/types";

function SendNewPrescriptionSuccessPopup({ onClose }: IPopupWithoutProps) {
  return (
    <article className={styles.popup}>
      <h2 className={styles.popup__title}>המרשם נשלח בהצלחה</h2>
      <img
        src={venchyImg}
        alt={"תמונה של וונצ’י"}
        className={styles.popup__img}
      />
      <button className={`button hover ${styles.popup__btn}`} onClick={onClose}>
        אישור
      </button>
    </article>
  );
}

export default SendNewPrescriptionSuccessPopup;
