import styles from "./usersPage.module.css";
import React, { ChangeEvent, useEffect, useState } from "react";
import useMediaQuery from "../../hooks/useMediaQuery";
import { useNavigate } from "react-router-dom";
import { filterObjectsByString } from "../../utils/search";
import { useAppSelector } from "../../services/hooks";
import {
  therapistUsersSelector,
  userSelector,
} from "../../services/selectors/user.selectors";
import { IUser, TUserForm } from "../../types/types";
import {
  useLazyGetUsersQuery,
  useTakeUserMutation,
} from "../../services/api/user.api";
import Loader from "../../components/loader/loader";
import AddButton from "../../components/addButton/addButton";
import AddNewUserForm from "../../components/forms/addNewUserForm/addNewUserForm";
import { TTakeUserRequest } from "../../services/types/user.types";
import Modal from "../../components/modals/modal/Modal";
import ErrorPopup from "../../components/modals/errorPopup/errorPopup";
import GeneralSuccessPopup from "../../components/modals/generalSuccessPopup/generalSuccessPopup";
import CloseButton from "../../components/closeButton/closeButton";

function UsersPage() {
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 800px)");
  const therapist = useAppSelector(userSelector);
  const therapistUsers = useAppSelector(therapistUsersSelector);
  const [getAllTherapistUsers, { isLoading }] = useLazyGetUsersQuery();
  const [takeUser] = useTakeUserMutation();

  const [openErrorModal, setOpenErrorModal] = useState(false);
  const [openSuccessModal, setOpenSuccessModal] = useState(false);

  const [userList, setUserList] = useState(therapistUsers);
  const [inputValue, setInputValue] = useState<string>("");
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    getAllTherapistUsers(therapist.id);
  }, [therapist.id, getAllTherapistUsers]);
  useEffect(() => {
    setUserList(therapistUsers);
  }, [therapistUsers]);

  const onChangeValue = (event: ChangeEvent<HTMLInputElement>) => {
    setInputValue(event.target.value);
    therapistUsers &&
      setUserList(() =>
        filterObjectsByString(event.target.value, therapistUsers)
      );
  };
  const sendTakeUserFormToServer = (values: TUserForm) => {
    let payload: TTakeUserRequest = {
      Id: "0",
      TherapistsId: therapist.id,
      FirstName: "",
      GenderId: 3,
      CompanyId: therapist.companyId,
    };
    for (let key in values) {
      payload[key.charAt(0).toUpperCase() + key.slice(1)] = values[key];
    }
    takeUser(payload)
      .then((res) => {
        // @ts-ignore
        if ("error" in res && res?.error?.originalStatus !== 200) {
          setOpenErrorModal(true);
        } else {
          setOpenSuccessModal(true);
        }
      })
      .finally(() => {
        getAllTherapistUsers(therapist.id);
        setShowForm(false);
      });
  };
  return (
    <section className={styles.users}>
      <article className={styles.usersTable}>
        <h2 className={`title`}>משתמשים</h2>
        <div className={styles.addButtonContainer}>
          {showForm ? (
            <CloseButton onClick={() => setShowForm(false)} />
          ) : (
            <AddButton
              onClick={() => {
                setShowForm(true);
              }}
              title={"הוסף משתמש חדש"}
            />
          )}
        </div>
        {showForm && <AddNewUserForm onSubmit={sendTakeUserFormToServer} />}
        <div className={styles.usersContent}>
          <div className={styles.usersSearchBar}>
            <input
              type={"text"}
              placeholder={"חיפוש"}
              className={styles.usersInput}
              onChange={onChangeValue}
              value={inputValue}
            />
          </div>
          {isLoading ? (
            <Loader />
          ) : (
            userList &&
            userList.length > 0 && (
              <div className={styles.usersListHeader}>
                <p className={styles.usersListTitle}>פעולות:</p>
                <p className={styles.usersListTitle}>מס' משתמש:</p>
                <p className={styles.usersListTitle}>שם משתמש:</p>
                <p className={styles.usersListTitle}>אימייל:</p>
                <p className={styles.usersListTitle}>טלפון:</p>
              </div>
            )
          )}
          {userList && userList.length ? (
            <ul className={`${styles.usersList} scroll`}>
              {userList.map((u: Partial<IUser>, index: number) =>
                !mobile ? (
                  <li
                    key={index}
                    className={`${styles.user} ${
                      index % 2 === 0 && styles.user_colored
                    }`}
                  >
                    <p className={styles.usersListCell}>
                      <button
                        className={`${styles.btn} ${styles.btn_type_actions}`}
                        onClick={() => {
                          navigate(`${u.Id}/update`);
                        }}
                      >
                        פעולות
                      </button>
                    </p>
                    <p className={styles.usersListCell}>{u.Id}</p>
                    <p className={styles.usersListCell}>
                      {u.FirstName} {u.LastName || ""}
                    </p>
                    <p className={styles.usersListCell}>{u.Email || "-"}</p>
                    <p className={styles.usersListCell}>{u.Phone || "-"}</p>
                  </li>
                ) : (
                  <li key={index} className={styles.user_mobile}>
                    <p className={styles.userName}>
                      <span className={styles.userNameLabel}> שם משתמש:</span>
                      {u.FirstName} {u.LastName || ""}
                    </p>
                    <p className={styles.usersListCell}>
                      <button
                        className={`${styles.btn} ${styles.btn_type_actions}`}
                        onClick={() => {
                          navigate(`${u.Id}/update`);
                        }}
                      >
                        פעולות
                      </button>
                    </p>
                  </li>
                )
              )}
            </ul>
          ) : (
            <p className={styles.noUsers}>לא נמצאו משתמשים</p>
          )}
          <p className={styles.usersTotal}>
            סה"כ משתמשים: {therapistUsers?.length}
          </p>
        </div>
      </article>
      {openErrorModal && (
        <Modal
          onClose={() => {
            setOpenErrorModal(false);
          }}
        >
          <ErrorPopup
            onClose={() => {
              setOpenErrorModal(false);
            }}
          />
        </Modal>
      )}
      {openSuccessModal && (
        <Modal
          onClose={() => {
            setOpenSuccessModal(false);
          }}
        >
          <GeneralSuccessPopup
            onClose={() => {
              setOpenSuccessModal(false);
            }}
          />
        </Modal>
      )}
    </section>
  );
}

export default UsersPage;
