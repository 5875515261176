import styles from "./editAvailabilityPage.module.css";
import React, { useState } from "react";
import Modal from "../../components/modals/modal/Modal";
import EditAvailabilityPopup from "../../components/modals/editAvailabilityPopup/editAvailabilityPopup";
import { IAvailability } from "../../types/types";
import { useAppSelector } from "../../services/hooks";
import { availabilitySelector } from "../../services/selectors/user.selectors";
import BackArrowButton from "../../components/backArrowButton/backArrowButton";
import { useNavigate } from "react-router-dom";

function EditAvailabilityPage() {
  const navigate = useNavigate();
  const availability = useAppSelector(availabilitySelector);
  const [showEditHoursPopup, setShowEditHoursPopup] = useState(false);
  const [dayToEdit, setDayToEdit] = useState<null | IAvailability>(null);
  const setHours = (day: IAvailability) => {
    setDayToEdit(day);
    setShowEditHoursPopup(true);
  };
  return (
    <section className={styles.section}>
      <BackArrowButton
        text={"לחזור"}
        onClick={() => navigate("/calendar")}
        position={"center"}
      />
      <h2 className={styles.title}>זמינות שלי</h2>
      <p className={styles.text}>
        כדי להגדיר שעות זמינות קבועות פשוט לחץ על יום כדי לערוך
      </p>
      <ul className={styles.days}>
        {availability.map((day, index) => (
          <li
            key={index}
            className={styles.daysLi}
            onClick={() => setHours(day)}
          >
            <p className={styles.daysTitle}>{day.title}</p>
            <div className={styles.daysContent}>
              {day.ranges.length ? (
                day.ranges.map((r, index) => (
                  <div key={index} className={styles.daysTimeContainer}>
                    <span className={styles.daysAvailableCircle} />
                    <p key={index} className={styles.daysText}>
                      {r.start.hours}:{r.start.minutes} - {r.end.hours}:
                      {r.end.minutes}
                    </p>
                  </div>
                ))
              ) : (
                <p
                  className={`${styles.daysText} ${styles.daysText_notAvailable}`}
                >
                  לא זמין
                </p>
              )}
            </div>
          </li>
        ))}
      </ul>
      <div className={styles.alternative}>או</div>
      <button
        className={`button hover ${styles.alternative__btn}`}
        onClick={() => navigate("monthly")}
      >
        לערוך זמינות חודשית
      </button>
      {showEditHoursPopup && dayToEdit !== null && (
        <Modal
          onClose={() => {
            setShowEditHoursPopup(false);
            setDayToEdit(null);
          }}
        >
          <EditAvailabilityPopup
            day={dayToEdit}
            onClose={() => {
              setShowEditHoursPopup(false);
              setDayToEdit(null);
            }}
          />
        </Modal>
      )}
    </section>
  );
}

export default EditAvailabilityPage;
