import React, { useRef, useState } from "react";
import videoStyles from "./video.module.css";

const Video: React.FC<{ src: string }> = ({ src }) => {
  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const togglePlay = () => {
    if (videoRef.current) {
      if (isPlaying) {
        videoRef.current.pause();
      } else {
        videoRef.current.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <>
      <video ref={videoRef} src={src} className={videoStyles.video} />
      <button
        className={`${videoStyles.playBtn} ${
          isPlaying && videoStyles.isPlaying
        }`}
        onClick={togglePlay}
      />
    </>
  );
};

export default Video;
